/**
 * Created by jgolebiewski on 14.09.2016.
 */
(function () {

    'use strict';

    angular.module('devices').directive('certificate', certificateDirective);


    function certificateDirective() {
        return {
            templateUrl: appRoot + '/devices/views/directives/certificate.directive.html',
            restrict: 'A',
            require: 'ngModel',
            controller: certificateController,
            controllerAs: 'cert',
            bindToController: true,
            link: function (scope, element, attr, ngModel) {

            }
        };
    }

    certificateController.$inject = ['$scope', 'countriesService'];

    function certificateController($scope, countriesService) {

        var vm = this;

        vm.countries = [];
        vm.countries_outsideEU = [];

        vm.patternOther = /.*?/;
        vm.patternPL = /^([0-9]{2})\/([0-9]{5})\/([0-9]{2})$/;
        vm.patternSK = /^[0-9]*$/;
        vm.patternCZ = /^([0-9]+)([a-z]{1,2})?\/([0-9]{2})$/i;
		vm.patternPLswiad = /.*?/;

		if ($scope.type == 2) {
			vm.patternSK = /.*?/;
		}

        vm.certificateVariants = [
            {id: 'PL', name: 'Polska/certyfikat'},
			{id: 'PLswiad', name: 'Polska/świad. kwalif. z Ustawy o SZWO z 2004 r. (tylko jeśli SZWO)'},
            {id: 'SK', name: 'Słowacja'},
            {id: 'CZ', name: 'Republika Czeska'},
            {id: 'NA', name: 'Inny'}
        ];

        setCountryName(vm.certificateVariants);

        getCountries();
        getCountriesOutsideEU();


        function getCountries() {
            countriesService.getCountries('ue').then(function(data){
                vm.countries = data;

                setCountryName(vm.countries, false);
            });
        }

        function getCountriesOutsideEU() {
            countriesService.getCountries('nonUE').then(function(data) {
                vm.countries_outsideEU = data;

                setCountryName(vm.countries_outsideEU, true);
            });
        }

        function setCountryName(countries, outsideEU) {
        	if ($scope.operations.operations && $scope.operations.operations.length && $scope.newOperation.technicians.length) {
        		var index = $scope.newOperation.technicians.length - 1;
        		if ($scope.newOperation.technicians[index].certificateCountry.id && !$scope.newOperation.technicians[index].certificateCountry.name) {
        			$.each(countries, function (idx, country) {
        				if (country.id == $scope.newOperation.technicians[index].certificateCountry.id) {
        					if (!country.name_pl) {
        						$scope.newOperation.technicians[index].certificateCountry.name = country.name;
        					} else {
        						$scope.newOperation.technicians[index].certificateCountry.name_pl = country.name_pl;
        					}
        					if (typeof outsideEU !== 'undefined' && !outsideEU) {
        						$scope.newOperation.technicians[index].certificateOtherCountry = angular.extend({}, $scope.newOperation.technicians[index].certificateCountry);
								$scope.newOperation.technicians[index].certificateCountry = { id: 'NA', name: 'Inny' };
        					}
        					if (typeof outsideEU !== 'undefined' && outsideEU) {
        						$scope.newOperation.technicians[index].certificateOtherCountry = angular.extend({}, $scope.newOperation.technicians[index].certificateCountry);
        						$scope.newOperation.outsideEU = 1;
        					}
        					return false;
        				}
        			});
        		}
        	}
        }

        function checkTypes(types) {
        	var isType = false;

			if ($scope.newOperation.type && $scope.newOperation.type.length) {
				var selectedTypes = $scope.newOperation.type;
				if (typeof selectedTypes === 'string') {
					selectedTypes = selectedTypes.split(',');
				}

		    	for (var type in selectedTypes) {
		    		type = selectedTypes[type];
			    	if ($.inArray(type, types) >= 0) {
			    		isType = true;
			    	} else {
			    		return false;
			    	}
		    	}
			}

			return isType;
        }

        $scope.$watch('newOperation.technicians', function (curr, old) {
        	var index = curr.length - 1;
        	if (curr[index].certificateCountry.id && !curr[index].certificateCountry.name) {
        		setCountryName(vm.certificateVariants);
    			setCountryName(vm.countries, false);
    			setCountryName(vm.countries_outsideEU, true);
        	}
        });


        vm.isRequired = function() {
        	if (
        		(
	        		$scope.$parent.data && $scope.$parent.data.categoryOne &&
	        		$scope.$parent.data.categoryOne == 7 && checkTypes(['Kontrola szczelności'])
        		) ||
        		checkTypes(['Instalowanie systemu wykrywania wycieku', 'Kontrola systemu wykrywania wycieków'])
        	) {
        		return false;
        	} else {
        		return true;
        	}
        };
    }

})(appRoot);