users.directive('suEditButton', ['SU_TYPES', 'suService', function (SU_TYPES, suService) {
    return {
        restrict: 'E',
        replace: false,
        scope: {
            user: '=user'
        },
        template:'<a ng-show="canEdit" data-ui-sref="users.su.edit({id: user.id})" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edytuj</span></a>',
        link: function(scope) {
            scope.canEdit = suService.canEdit(scope.user);
        }
    }
}]);
