(function () {

    'use strict';


    angular.module('App').controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$scope', '$uibModal', 'usersService', '$rootScope', 'newsService', 'NoticesResource', 'NoticeUsersResource'];

    function DashboardController ($scope, $uibModal, usersService, $rootScope, newsService, NoticesResource, NoticeUsersResource) {
        var self = this;
        $scope.loaders = {
            news: true,
            docs: true,
            links: true
        };



        getLinks();
        getDocuments();
        getNews();



        function getLinks() {

            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.links = true;
            newsService.getLinks(params).then(function(response){
                $scope.links = response.data;
                $scope.loaders.links = false;
            });
        }

        function getDocuments() {

            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.docs = true;
            newsService.getDocuments(params).then(function(response){
                $scope.documents = response.data;
                $scope.loaders.docs = false;
            });
        }

        function getNews() {
            var params = {
                limit: 5,
                page: 0,
                status: 1
            };
            $scope.loaders.news = true;
            newsService.getNews(params).then(function(response){
                $scope.news = response.data;
                $scope.loaders.news = false;
            });
        }

		
		if ($scope.firstLoginInfo == true) {

			$scope.open = function () {

				$scope.modalInstance = $uibModal.open({
					templateUrl: appRoot + '/application/views/passwd.modal.html',
					size: 'od-lg',
					animation: true,
					backdrop: 'static',
					keyboard: false,
					scope: $scope
				});
			};
			$scope.open();
			$scope.save = function () {
				$scope.modalInstance.close();
				usersService.markFirstLogin().then(function (response) {
					if (response.success == true) {
						$scope.modalInstance.close();
						$rootScope.firstLoginInfo = false;
						$scope.loggedUser.firstLogin = new Date();
						LOGGED_USER.firstLogin = new Date();
					} else {
						$scope.modalInstance.close(); //zamykam, w przyszłości ponownie pojawi się prośba o zmianę hasła
					}
				});
			};
		}
		

		if ($scope.loggedUser.role.name != $scope.userRoles.su) {
			var params = { unread: 1 };
			if ($rootScope.firstLogin) {
				params.type = 0;
			}

			NoticesResource.get(params).$promise.then(function (response) {
		        if (response.success == true) {
		            $scope.notices = response.data;
		    		if ($scope.notices.length) {
		    			$scope.notice = $scope.notices[0];
		    			$scope.openNoticeModal();
		    		}
		        }
		    });
		}

	    $scope.read = function (notice) {
	        NoticeUsersResource.save({ noticeId: notice.id }).$promise.then(function (response) {
	            if (response.success == true) {
	                //toaster.pop('success', '');
	                $scope.noticeModal.dismiss('cancel');
	                $scope.nextNotice();
	            } else {
	                toaster.pop('error', response.error);
	            }
	        });
	    };

	    $scope.openNoticeModal = function () {
	    	$scope.noticeModal = $uibModal.open({
	    		templateUrl: appRoot + '/notices/views/notice.modal.html',
	    		size: 'large',
	    		animation: true,
	    		backdrop: 'static',
	    		keyboard: false,
	    		scope: $scope,
	    	});
	    };

	    $scope.cancelNoticeModal = function () {
	        $scope.noticeModal.dismiss('cancel');
	        $scope.nextNotice();
	    };

	    $scope.nextNotice = function () {
	    	$scope.notices.shift();
	    	if ($scope.notices.length) {
	    		$scope.notice = $scope.notices[0];
	    		$scope.openNoticeModal();
	    	}
	    };
    }

})();