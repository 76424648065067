/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('companyHistoryResource', ['$resource', function ($resource) {
    return $resource('/companiesHistory/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    });
}]);