devices.controller('NewDeviceController', ['$scope', 'devicesResource', 'type', 'deviceEntity', 'mode', 'moment', '$state','chemicalsResource','devicesService', 'companyService', 'provincesService', 'usersResource','$filter','toaster','Session',
    function ($scope, devicesResource, type, deviceEntity, mode, moment, $state, chemicalsResource, devicesService, companyService, provincesService, usersResource, $filter, toaster,Session) {


        if (type == null) {
            return $state.go("devices.list");
        }

        $scope.status = {opened: false};

        $scope.open = function($event) {
            $scope.status.opened = true;
        };

        $scope.isContactUser = $scope.loggedUser.role.name == $scope.userRoles.user;

        $scope.editDisabled = false;
        $scope.type = type;
        $scope.unSelectable = false;


        $scope.checkCategoriesTwo = checkCategoriesTwo;

        $scope.mode = (mode == undefined) ? 'preview' : mode;

        $scope.user = {
            username: $scope.loggedUser.email,
            password: ''
        };	
	

        $scope.setMode = function () {
            return $state.go("devices.list");
        };


        $scope.dateFormat = 'dd/MM/yyyy';

        $scope.dateRangePickerOptions = {
            singleDatePicker: true,
            showDropdowns: true,
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            }
        };

        $scope.voivodeships = provincesService.getProvincesWithoutNames();

        $scope.mode = 'edit';
        $scope.data = {
            createDate: moment().format('DD/MM/YYYY'),
            user: angular.copy($scope.loggedUser),
            company: {nip: $scope.loggedUser.company.nip},
            companyName: $scope.loggedUser.company.name != null ? $scope.loggedUser.company.name : $scope.loggedUser.company.firstName + ' ' + $scope.loggedUser.company.surname,
			contact_firstName: $scope.loggedUser.firstName,
			contact_surname: $scope.loggedUser.surname,
			contact_email: $scope.loggedUser.email,
			contact_phone: $scope.loggedUser.phone,
            homeNumber:  $scope.loggedUser.company.homeNumber,
            flatNumber:  $scope.loggedUser.company.flatNumber,
            street:  $scope.loggedUser.company.street,
            city:  $scope.loggedUser.company.city,
            postcode:  $scope.loggedUser.company.postcode,
            voivodeship: $scope.loggedUser.company.province,
            leak_detection_system: 0,
            hermetically_sealed: 0,
            in_residential_building: 0,
            users: [],
        };

        $scope.contact = { users: [] };
        $scope.contacts = [];
        $scope.contacts.push($scope.data.user.id);

        $scope.$watch('data.original_quantity', function (newValue, oldValue) {
        	if (newValue != oldValue) {
        		$scope.data.quantity = parseFloat(newValue || 0) + parseFloat($scope.data.installation_quantity || 0);
        	}
        });

        $scope.$watch('data.installation_quantity', function (newValue, oldValue) {
        	if (newValue != oldValue) {
        		$scope.data.quantity = parseFloat($scope.data.original_quantity || 0) + parseFloat(newValue || 0);
        	}
        });

        $scope.$watch('data.hermetically_sealed', function (newValue, oldValue) {
        	if (newValue == 0 && $scope.data.in_residential_building == 1) {
        		$scope.data.in_residential_building = 0;
        	}
        	if (newValue == 1) {
        		$scope.data.installation_quantity = 0;
        	}
        });

        $scope.searchChemicalTxt = '';
        $scope.chemicals = [];
        $scope.chemicalsLoading = false;
        $scope.searchChemicals = function(searchTxt) {

            if (searchTxt.length == 0) {
            	if ($scope.data.chemical && $scope.chemicals.length == 0) {
            		searchTxt = $scope.data.chemical.ashrae;
            	} else {
            		return false;
            	}
            }

            $scope.searchChemicalTxt = searchTxt;

            var type = null;
            var conditions = {
        		type: $scope.type,
        		category: $scope.data.category_one ? $scope.data.category_one.id : null,
        	};

            $scope.chemicalsLoading = true;

            devicesService.getChemicals(searchTxt, type, conditions).then(function(response){
                if (response.success == true) {
                    $scope.chemicals  = response.data;
                    //$scope.data.category_one = null;
                    //$scope.data.category_two = null;
                    $scope.chemicalsLoading = false;
                    //$scope.unSelectable = false;

                    if ($scope.data.chemical) {
                    	$.each($scope.chemicals, function (index, chemical) {
                    		if (chemical.id == $scope.data.chemical.id && chemical.subjectToRegistration != $scope.data.chemical.subjectToRegistration) {
                    			$scope.data.chemical.subjectToRegistration = chemical.subjectToRegistration;
                    			return false;
                    		}
                    	});
                    }
                }
            });
        };


        $scope.deviceCategories = [];
        $scope.catsLoading = false;
        $scope.getDeviceCategoriesOne = function (selectedItem) {

            if (selectedItem == undefined) {
                $scope.deviceCategories = [];
                $scope.data.category_one = null;
                $scope.data.category_two = null;
                return;
            }

            $scope.catsLoading = true;


            devicesService.getCategories(selectedItem.type, $scope.type, selectedItem.id).then(function(response){
                $scope.catsLoading = false;
                if (response.success == true) {
                    $scope.deviceCategories = response.data;
                }
            });

        };

        $scope.clientsLoaded = false;
        $scope.searchUser = function(searchTxt) {
            if (searchTxt.length == 0) {
                return false;
            }

            var data = {};
            data['filters[0][field]'] = 'users.surname';
            data['filters[0][search]'] = searchTxt;

            $scope.clientsLoaded = true;

            usersResource.search(data, function(response) {
                if (response.success == true) {
                    $scope.users = response.data;
                    $scope.clientsLoaded = false;
                }
            });
        };

        $scope.addContactUser = function () {
        	if ($scope.data.users.length >= 2) {
        		return;
        	}

            $scope.data.users.push({
                firstname: null,
                surname: null,
				email: null,
        		phone: null,
            });
        };

        $scope.removeContactUser = function ($index) {
        	var index = $scope.contacts.indexOf($scope.data.users[$index].userId);
            if (index !== -1) {
            	$scope.contacts.splice(index, 1);
            }

            $scope.data.users.splice($index, 1);
        };

        $scope.setContactUser = function ($index, $item) {
        	if ($scope.contacts.indexOf($item.id) !== -1) {
        		return;
        	}
        	if ($scope.data.users[$index] && $scope.data.users[$index].userId) {
	        	var index = $scope.contacts.indexOf($scope.data.users[$index].userId);
	            if (index !== -1) {
	            	$scope.contacts.splice(index, 1);
	            }
        	}

            $scope.data.users[$index] = {
            	id: $scope.data.users[$index].id || null,
            	userId: $item.id,
                firstname: $item.firstName,
                surname: $item.surname,
				email: $item.email,
        		phone: $item.phone,
            };

            $scope.contacts.push($item.id);
        };

        getCurrentAdmin();


        $scope.page = 'device';
        $scope.label = "Karta urządzenia";

        if ($scope.type == 2) {
            $scope.page = 'fire.protection';
            $scope.label = 'Karta Systemu Ochrony Przeciwpożarowej'
        }

        $scope.loadingContent = true;
        $scope.$on('$includeContentLoaded', function (event) {
            $scope.loadingContent = false;
        });


        $scope.saving = false;
        $scope.save = function () {

            $scope.saving = true;
            $scope.data.productionDate = $filter('date')($scope.data.productionDate, 'yyyy-MM-dd');
            $scope.data.categoryOne = $scope.data.category_one.id;
            $scope.data.categoryTwo = ($scope.data.category_two != undefined) ? $scope.data.category_two.id : null;
            $scope.data.type = $scope.type;

            /*
            $.each($scope.data.users, function (index, user) {
	        	if (
					user.userId && $scope.contact.users && $scope.contact.users[index] && (
						user.firstname != $scope.contact.users[index].firstName ||
			            user.surname != $scope.contact.users[index].surname ||
						user.email != $scope.contact.users[index].email ||
			    		user.phone != $scope.contact.users[index].phone
		    		)
				) {
					$scope.data.users[index].userId = null;
				}
        	});
            */

            devicesResource.save($scope.data, function (response) {

                if (response.success == true) {
                    toaster.pop('success', "Dane zapisane");
                    Session.addCart(response.data.id);
                    $scope.saving = false;
                    return $state.go("devices.list.edit", {id: response.data.id})
                } else {
                    $scope.saving = false;
                    toaster.pop('error', response.error);
                }
            });
        };

        function checkCategoriesTwo ($selectedItem) {

            if (devicesService.checkCategoriesTwo($selectedItem) == true) {
                $scope.data.category_two = null;
                $scope.unSelectable = true;
            } else {
                $scope.unSelectable = false;
            }

        }

        function getCurrentAdmin() {
            companyService.getAdminCurr($scope.loggedUser.company.id).then(function(data) {
                $scope.data.admin = data;
            });
        }

        $scope.clearChemical = function ($event, $select) {
			$event.stopPropagation();
			$select.selected = null;
			//$select.search = undefined;
			//$select.activate();

			$scope.deviceCategories = [];
		    $scope.data.category_one = null;
		    $scope.data.category_two = null;
		}

        $scope.clearCategory = function ($event, $select) {
			$event.stopPropagation();
			$select.selected = null;
			//$select.search = undefined;
			//$select.activate();

			$scope.data.category_one = null;
			$scope.data.category_two = null;

			$scope.searchChemicals($scope.searchChemicalTxt);
		}

        $scope.quantityMin = function () {
			if ($scope.data.chemical) {
				if ($scope.data.chemical.type == 'FGC' || $scope.data.chemical.type == 'MIX') {
					if ($scope.data.hermetically_sealed == 1 && $scope.data.chemical.leakage_test_by_quantity == 1 && parseFloat($scope.data.chemical.subjectToRegistration) < 2) {
						return 2;
					}
					return $scope.data.chemical.subjectToRegistration || 0;
				}
				else if ($scope.data.chemical.type == 'SZWO') {
					return 3;
				}
			} else {
				return 0;
			}
		}

        $scope.checkEquivalent = function () {
			return (
				$scope.type == 1 &&
				$scope.data.hermetically_sealed == 1 &&
				$scope.data.chemical.gwp &&
				$scope.data.chemical.leakage_test_by_quantity != 1 &&
				(((($scope.data.quantity || 0) * $scope.data.chemical.gwp) / 1000).toFixed(0)) < 10
			);
		};

        $scope.inResidentialBuildingRequired = function () {
			var categories = [9, 13];
			var categoryTwo = ($scope.data.category_two != undefined ? $scope.data.category_two.id : 0);

			return ($scope.type == 1 && $scope.data.hermetically_sealed == 1 && parseFloat($scope.data.quantity) < 3 && categoryTwo && categories.indexOf(categoryTwo) != -1);
		};

		$scope.formInvalid = function () {
        	return ($scope.type == 1 && (
        		($scope.data.chemical.gwp && $scope.checkEquivalent()) ||
        		($scope.inResidentialBuildingRequired() && $scope.data.in_residential_building == 1)
        	));
        };
    }]);

