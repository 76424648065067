notifications.service('deviceHistoryService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'deviceHistoryResource', 'toaster',
    function ($http, uiGridConstants, moment, gridService, deviceHistoryResource, toaster) {
        var self = this;

        self.resource = deviceHistoryResource;
        self.gridApi = null;
        self.scope = null;
        self.resourceParams = {};

        var dateRangePickerOptions = {
            locale: {
                'format': 'DD/MM/YYYY',
                'separator': ' - ',
                'applyLabel': 'Wybierz',
                'cancelLabel': 'Anuluj',
                'fromLabel': 'Od',
                'toLabel': 'Do',
                'customRangeLabel': 'Zdefiniuj'
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: 'Modyfikował',
                field: 'changer',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.changer.firstName }} {{ row.entity.changer.surname }}</div>'
            },
            {
                name: 'Nazwa',
                field: 'devices_history.name',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Model',
                field: 'model',
                fieldType: 'text'
            },
            {
                name: 'Numer seryjny',
                field: 'serialNumber',
                fieldType: 'text'
            },
            {
                name: 'Data produkcji',
                field: 'productionDate',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
				cellTemplate: '<div class="ui-grid-cell-contents text-center">{{ row.entity.productionDate | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Kategoria',
                field: 'category_one.name',
                fieldType: 'text'
            },
            {
                name: 'Podkategoria',
                field: 'category_two.name',
                fieldType: 'text'
            },
            {
                name: 'Substancja lub mieszanina',
                field: 'chemical.name',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.chemical.name }}</div>'
            },
            {
                name: 'Ilość substancji',
                field: 'quantity',
				fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.quantity }}</div>'
            },
			{
                name: 'Miejsce eksploatacji',
                field: 'exploitation_address',
                fieldType: 'text',
                enableFiltering: false,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.exploitation_street }} {{ row.entity.exploitation_homeNumber }} {{ row.entity.exploitation_flatNumber }} {{ row.entity.exploitation_postcode }} {{ row.entity.exploitation_city }}</div>'
            },
            {
                name: 'Data aktualizacji',
                field: 'devices_history.created_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents text-center">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
        ];

        self.getGridOptions = function () {
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();
            gridOptions.columnDefs = self.getGridColumns();

            return gridOptions;
        };

        self.getGridColumns = function () {
            return gridColumns;
        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        function response(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn(response);
            }
        }

        return self;
    }]);