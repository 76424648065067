devices.controller('PermissonsController', ['$scope', 'devicesResource', 'usersResource', 'toaster',
    function ($scope, devicesResource, usersResource, toaster) {

        $scope.showPermissionForm = {s: false};
        if ($scope.$parent.isAuthorized != true) {
            return;
        }

        $scope.isAuthorized = true;

        $scope.operations = [];

        $scope.newOperation = {
            technicians: [{
                firstName: null,
                surname: null,
                certificate: null
            }]
        };

        $scope.permission = {};
        $scope.user = {};
        $scope.data.operator = {};
        $scope.data.contacts = [];

        $scope.contacts = [];

        var getPermission = function () {
            devicesResource.get({id: $scope.$parent.data.id, action: 'permissions'}, function (response) {
                if (response.success == true) {
                    $scope.user = response.data;
                    $scope.data.operator = response.data;

                    $scope.data.contacts = [
                    	{
                    		userId: $scope.user.id,
                    		firstname: $scope.user.firstName,
                    		surname: $scope.user.surname,
                    		email: $scope.user.email,
                    		phone: $scope.user.phone,
                    		rolename: $scope.user.role.name,
                    	}
                    ];
                    $scope.contacts.push($scope.user.id);
                    angular.forEach(response.data.contacts || [], function(user, key) {
                    	if (user.user) {
                    		user.user.firstname = user.user.firstName;
                    		user.user.userId = user.user.id;
                    		user.user.id = user.id;
                    	}
                    	$scope.data.contacts.push(user.user || user);
                    	$scope.contacts.push(user.userId);
                    });
                }
            });
        };

        getPermission();

        $scope.removePermission = function () {
            devicesResource.save({id: $scope.$parent.data.id, action: 'unsetPermissions'}, {uid: $scope.user.id}, function (response) {
                if (response.success == true) {
                    getPermission();
                    toaster.pop('success', "Dostęp do karty zablokowany");
                }
            });
        };

        $scope.clientsLoaded = false;
        $scope.searchUser = function(searchTxt) {
            if (searchTxt.length == 0) {
                return false;
            }


            var string = {};
            string['filters[0][field]'] = 'users.surname';
            string['filters[0][search]'] = searchTxt;

            $scope.clientsLoaded = true;
            usersResource.search(string, function(response){
                if (response.success == true) {
                    $scope.users  = response.data;
                    $scope.clientsLoaded = false;
                }
            });
        };


        $scope.addPermission = function (formData) {
            $scope.showPermissionForm = {s: false};
            devicesResource.update({ id: $scope.$parent.data.id, action: 'permissions' }, {
            	uid: formData.user ? formData.user.id : null, contacts: $scope.data.contacts
            }, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Dostęp nadany");
                    getPermission();

                    $scope.getDevice();
                } else {
                    toaster.pop('error', response.error);
                }
            });
        };

        $scope.addContactUser = function () {
        	if ($scope.data.contacts.length >= 3) {
        		return;
        	}
        	if ($scope.contacts.indexOf($scope.permission.user.id) !== -1) {
        		return;
        	}

            $scope.data.contacts.push({
            	userId: $scope.permission.user.id,
                firstname: $scope.permission.user.firstName,
                surname: $scope.permission.user.surname,
				email: $scope.permission.user.email,
        		phone: $scope.permission.user.phone,
        		rolename: $scope.permission.user.role.name,
            });

            $scope.contacts.push($scope.permission.user.id);
        };

        $scope.removeContactUser = function ($index) {
        	var index = $scope.contacts.indexOf($scope.data.contacts[$index].userId);
            if (index !== -1) {
            	$scope.contacts.splice(index, 1);
            }

            $scope.data.contacts.splice($index, 1);
        };
    }]);

