register.directive('regCertificate', function () {
    return {
        templateUrl: appRoot + '/register/views/directives/certificate.directive.html',
        restrict: 'A',
        require: 'ngModel',
        controller: ['$scope', 'countriesService', function ($scope, countriesService) {
        	$scope.regCertificateData = {};

            $scope.regCertificateData.countries = [];

            $scope.regCertificateData.patternOther = /.*?/;
            $scope.regCertificateData.patternPL = /^([0-9]{2})\/([0-9]{5})\/([0-9]{2})$/;
            $scope.regCertificateData.patternSK = /^[0-9]*$/;
            $scope.regCertificateData.patternCZ = /^([0-9]+)([a-z]{1,2})?\/([0-9]{2})$/i;
    		$scope.regCertificateData.patternPLswiad = /.*?/;

            $scope.regCertificateData.certificateVariants = [
                { id: 'PL', name: 'Polska/certyfikat' },
    			{ id: 'PLswiad', name: 'Polska/świad. kwalif. z Ustawy o SZWO z 2004 r. (tylko jeśli SZWO)' },
                { id: 'SK', name: 'Słowacja' },
                { id: 'CZ', name: 'Republika Czeska' },
                { id: 'NA', name: 'Inny' }
            ];

            //getCountries();

            function getCountries() {
                countriesService.getCountries('ue').then(function(data) {
                    $scope.regCertificateData.countries = data;
                });
            }
        }],
        controllerAs: 'regCertificateController',
        bindToController: true,
        link: function (scope, element, attr, ngModel) {
        }
    };
});