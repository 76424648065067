register.factory('techniciansSubscriptionsResource', ['$resource', function ($resource) {
    return $resource('/technicianssubscriptions/:action/:id', { action: '@action', id: '@id' }, {
        save: {
            method: 'POST',
            params: {
                action: ''
            }
        },
        update: {
            method: 'PUT'
        },
    });
}]);