users.directive('sendActivateMessage', ['registerResource', 'toaster', function (registerResource, toaster) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            userId: '=userId'
        },
        template: '<button data-ng-click="sendActivateMessage()" class="btn btn-info btn-sm">Wyślij wiadomość z aktywacją</button>',
        link: function(scope) {
            scope.sendActivateMessage = function() {
                registerResource.get({id: scope.userId, action: 'sendActivateMessage'}, function (response) {
                    if (response.success == true) {
                        toaster.pop('success', "Wiadomość wysłana");
                    } else {
                        toaster.pop('error', response.error);
                    }
                });
            }
        }
    }
}]);