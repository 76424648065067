App.service('DateTimeService', ['moment', function (moment) {

    var self = this;

    self.convertToTimeObject = function (dateFromAPI, toString) {
        console.log(dateFromAPI);
        var timeString = dateFromAPI.split(":");
        var tmp2 = new Date();
        tmp2.setHours(timeString[0]);
        tmp2.setMinutes(timeString[1]);

        if (toString != undefined && toString == true) {
            return timeString[0] + ":" + timeString[1];
        }

        return tmp2;
    };


    self.convertToMomentObj = function (date) {
         return new Date(date);
    };

    self.convertToDateObject = function (dateFromAPI) {
        //var tmp = dateFromAPI.split(" ");
        return new Date(dateFromAPI);
    };

    self.removeMilliseconds = function (dateFromApi) {
        var tmp = dateFromApi.date.split(".");
        return tmp[0];
    };

    self.timeToSeconds = function (str) {
        str += ":00";
        var p = str.split(':'),
            s = 0, m = 1;

        while (p.length > 0) {
            s += m * parseInt(p.pop(), 10);
            m *= 60;
        }

        return s;
    };

    self.secondsToTime = function (input, asObject, asInteger) {
        var seconds = Math.floor(input),
            hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        var minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        var asInteger = (asInteger == undefined) ? false : asInteger;

        if (hours >= 24) {
            hours = hours - 24;
        }

        if (hours < 10) {
            hours = asInteger == false ? "0" + hours : hours;
        }

        if (minutes < 10) {
            minutes = asInteger == false ? "0" + minutes : minutes;
        }

        if (seconds < 10) {
            seconds = asInteger == false ? "0" + seconds : seconds;
        }

        if (asObject != undefined && asObject == true) {
            return {
                hour: hours,
                minute: minutes,
                seconds: seconds
            }
        }

        return hours + ':' + minutes;
    };

    self.secondsToDuration = function (input){
        var seconds = Math.floor(input),
            hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        var minutes = Math.floor(seconds / 60);

        return hours + " " + " " +minutes + " " ;
    };

    self.secondsToDateTimeObject = function (input) {
        var obj = this.secondsToTime(input, true);
        var tmp2 = new Date();
        tmp2.setHours(obj.hour);
        tmp2.setMinutes(obj.minute);
        return tmp2;
    };

    self.dateTimeObjectToSeconds = function (input) {
        if (input == undefined) {
            return console.warn("DateTimeService waring - bad value:", input)
        }
        var tmp1 = input.getHours() + ":" + input.getMinutes();
        return self.timeToSeconds(tmp1);
    };

    self.hoursToSeconds = function (input) {
        return input * 3600;
    };

    self.minutesToSeconds = function (input) {
        return input * 60;
    };

    self.objectToSeconds = function (input) {
        return self.hoursToSeconds(input.hour) + self.minutesToSeconds(input.minute);
    };

    return self;
}]);