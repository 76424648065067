/**
 * Created by jgolebiewski on 29.12.2015.
 */
App.directive('uiSelectRequired', function () {
    return {
        require: 'ngModel', link: function (scope, elm, attrs, ctrl) {

            var requiredDepency = true;

            if (attrs.ngRequired != undefined) {

                scope.$watch(attrs.ngRequired, function (nVal, oVal) {
                    //console.log("watch", nVal, oVal);
                    requiredDepency = nVal == null;
                    //console.log(nVal, requiredDepency);

                    ctrl.$setValidity('uiSelectRequired', requiredDepency);
                });

            }

            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
                //console.log(modelValue, viewValue, requiredDepency);
                if (requiredDepency == true && attrs.ngRequired != undefined) {
                    return true;
                }

                if (modelValue == undefined && viewValue.length == 0 && requiredDepency == false) {
                    //console.log("tu");
                    return true;
                }

                var determineVal;
                if (angular.isArray(modelValue)) {
                    determineVal = modelValue;
                } else if (angular.isArray(viewValue)) {
                    determineVal = viewValue;
                } else {
                    return false;
                }


                return determineVal.length > 0;
            };


        }
    };
});