users.controller('UsersListController', ['$scope', 'usersService', 'usersResource', 'rolesResource', 'toaster','$uibModal','remindResource',
    function ($scope, usersService, usersResource, rolesResource, toaster, $uibModal, remindResource) {

		$scope.listName = 'UsersList';

        $scope.showForm = {s: false};

        var newData = {
            firstName: null,
            surname: null,
            email: null,
            emailRepeat: null,
            status: 0
        };

        $scope.type = 'new';
        $scope.data = angular.copy(newData);


        usersService.scope = $scope;
        usersService.resource = usersResource;
        $scope.gridOptions = usersService.getGridOptions();


        rolesResource.get({}, function (response) {

            var roles = [];
            angular.forEach(response.data, function(role){

                    roles.push({
                        id: role.id,
                        label: role.name == 'ADMIN' ? 'Administrator' : 'Osoba kontaktowa'
                    })

            });

            usersService.roles = roles;

            var index = 5;
            if ($scope.loggedUser.role.name != $scope.userRoles.su) {
                index = 3;
            }

            $scope.gridOptions.columnDefs[index].filter.options = roles;
        });


        usersService.getPage();

        $scope.intiForm = function (form) {
            $scope.userForm = form;
        };

        var sendEmail = function () {
            remindResource.save({email: $scope.data.email}, function (response) {

                if (response.success == true) {
                    toaster.pop('success', "Wiadomość z hasłem wysłana");

                } else {
                    toaster.pop('error', response.error);
                }
            });
        };

        $scope.save = function () {

            $scope.saving = true;
            usersResource.save($scope.data , function (response) {
                $scope.saving = false;
                $scope.showForm.s = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został dodany");
                    //sendEmail();
                    usersService.getPage();
                   resetForm();
                } else {
                    toaster.pop('error', response.error);
                }
            });

        };


        $scope.closeForm = function () {
            $scope.showForm.s = false;
            resetForm();
        };


        $scope.open = function (id) {

            $scope.idToDelete = id;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                size: 'small',
                animation: true,
                scope: $scope,
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });
        };

        $scope.cancel = function () {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        };

        $scope.delete = function () {

            usersResource.delete({id: $scope.idToDelete }, function (response){
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został usunięty");
                    $scope.idToDelete = null;
                    usersService.getPage();
                } else {
                    toaster.pop('error', response.error);
                }
                $scope.modalInstance.close();
            });
        };


        function resetForm() {
            $scope.data = angular.copy(newData);
            $scope.userForm.$setSubmitted(true);
            $scope.userForm.$setUntouched(true);
        }
    }]);