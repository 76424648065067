devices.controller('DevicesListController', ['$scope', 'devicesResource', 'uiGridConstants', 'devicesService',
    function ($scope, devicesResource, uiGridConstants, devicesService) {

		$scope.listName = 'DevicesList';
        $scope.type = 1;

        $scope.setType = function (type) {
            $scope.type = type;
            devicesService.getPage($scope.type);
        };


        devicesService.scope = $scope;
        devicesService.resource = devicesResource;
        $scope.gridOptions = devicesService.getGridOptions();

        devicesService.getPage($scope.type);

        $scope.devicesCount = 0;

        function devicesCount()
        {
            devicesService.devicesCount().then(function (response) {
                $scope.devicesCount = response.data;
            });
        }

        devicesCount();
    }]);

