devices.controller('CompanyDevicesListController', ['$scope', 'devicesResource', 'uiGridConstants', 'toaster', 'companyDevicesService', 'companyId', 'companyEntity','$state', '$window',
    function ($scope, devicesResource, uiGridConstants, toaster, companyDevicesService, companyId, companyEntity, $state, $window) {

		$scope.listName = 'CompanyDevicesList';

        if (companyEntity.success == false) {
            toaster.pop('error', companyEntity.error);
            return $state.go('devices.list');
        }

        $scope.company = companyEntity.data;

        companyDevicesService.scope = $scope;
        companyDevicesService.resource = devicesResource;
        companyDevicesService.resourceParams = {action: 'company', subId: companyId};

        $scope.gridOptions = companyDevicesService.getGridOptions();

        companyDevicesService.getPage();

        $scope.getXLS = function () {
        	var filename = 'raport.xlsx';
            companyDevicesService.getXLS(companyId).then(function(hash) {
            	if (hash) {
            		$window.location.href = '/devices/' + hash + '/' + filename;
            	}
            });
        };
    }]);

