
App.controller('HeaderController', ['$scope', 'MenuService', function($scope, MenuService) {
    $scope.$on('$includeContentLoaded', function() {
        $scope.menuItems = MenuService.getItems();
    });


    $scope.user = $scope.loggedUser;




}]);