dictionaries.controller('DictionariesListController', ['$scope', 'dictionariesResource','dictionariesService', 'toaster','$uibModal', 'devicesService',
    function ($scope, dictionariesResource, dictionariesService, toaster, $uibModal, devicesService) {
    var self = this;

    $scope.listName = 'DictionariesList';

    dictionariesService.scope = $scope;
    dictionariesService.resource = dictionariesResource;
    $scope.gridOptions = dictionariesService.getGridOptions();


    dictionariesService.getPage();


    $scope.show = {form: false};

    $scope.data = {
        type: 'SZWO',
        hidden: 0,
        leakage_test_by_quantity: 0,
        components: []
    };

    $scope.defaultData = $scope.data;

    $scope.$watch('data.type', function (newValue, oldValue) {
    	if (newValue == '' && $scope.data.hidden != 1) {
    		$scope.data.hidden = 1;
    	}
    });

    $scope.showForm = function (status) {
        $scope.show.form = status;
    };

    $scope.saving = false;
    $scope.save = function () {
        $scope.saving = true;

        if ($scope.data.id == null) {
	        dictionariesResource.save($scope.data, function (response) {
	            $scope.saving = false;
	            if (response.success == true) {
	                $scope.show = {form: false};
	                toaster.pop('success', "Pozycja dodana");
	                $scope.data = $scope.defaultData;
	            } else {
	                toaster.pop('error', response.error);
	            }
	        });
        } else {
        	dictionariesResource.update({ id: $scope.data.id }, $scope.data, function (response) {
                $scope.saving = false;
                if (response.success == true) {
                	$scope.show = { form: false };
                    toaster.pop('success', 'Dane uaktualnione');
                    $scope.data = $scope.defaultData;
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }
    };

    $scope.idToDelete = null;

    $scope.open = function (id) {

        $scope.idToDelete = id;
        $scope.modalInstance = $uibModal.open({
            templateUrl: appRoot + '/common/views/modals/delete.modal.html',
            size: 'small',
            animation: true,
            scope: $scope,
            resolve: {
                items: function () {
                    return $scope.items;
                }
            }
        });
    };


    $scope.delete = function () {

        dictionariesResource.delete({id: $scope.idToDelete }, function (response){
            if (response.success == true) {
                toaster.pop('success', "Pozycja została usunięta");
                $scope.idToDelete = null;
                dictionariesService.getPage();
            } else {
                toaster.pop('error', response.error);
            }
            $scope.modalInstance.close();
        });
    };

    $scope.setData = function (data) {
    	$scope.data = data;
    };

    $scope.chemicals = [];

    $scope.searchChemicals = function (searchTxt) {
        if (searchTxt.length == 0) {
        	if ($scope.data.chemical && $scope.chemicals.length == 0) {
        		searchTxt = $scope.data.ashrae;
        	} else {
        		return false;
        	}
        }

        dictionariesService.getChemicals(searchTxt).then(function (response) {
            if (response.success == true) {
                $scope.chemicals = response.data;
            }
        });
    };

    $scope.clearChemical = function ($event, $select) {
		$event.stopPropagation();
		$select.selected = null;
	}

    $scope.addComponent = function () {
        $scope.data.components.push({
            chemical: {},
			share: null,
        });
    };

    $scope.removeComponent = function ($index) {
        $scope.data.components.splice($index, 1);
    };
}]);

