(function() {
  'use strict';

  angular.module('app.export')
    .service('ExportService', ExportService);

  //ExportService.$inject = ['ExportResource', 'sectionsResource', 'sectionFieldsResource', 'productTypesResource'];
  ExportService.$inject = ['ExportResource'];

  function ExportService(ExportResource, sectionsResource, sectionFieldsResource, productTypesResource) {

    return  {
      generateReport: generateReport,
      getSections: getSections,
      getSectionFields: getSectionFields,
      getProductTypes: getProductTypes
    };

    //////////////////////////////////////////////
    function generateReport(criteria) {
      // TODO: filtrowanie kryteriów przed api call
      console.log('generateReport', criteria);
      return ExportResource.generate(criteria).$promise.then(function(response) {
        if (response.success === true) {
          return response.data.hash;
        } else {
          // TODO: Lepsza obsługa błędów
          console.error("Błąd generowania raportu", response);
          return false;
        }
      });
    }

    function getProductTypes() {
      return productTypesResource.get().$promise.then(parseData);
    }

    function getSections() {
      return null;
	  return sectionsResource.get().$promise.then(parseData);
    }

    function getSectionFields() {
	  return null;
      return sectionFieldsResource.get().$promise.then(parseData);
    }


    function parseData(response) {
      if (response.success == true) {
        return response.data;
      } else {
        console.error("Błąd zapytania", response);
      }
    }
  }
})();