messages.controller('MessagesTemplateController', ['$scope', 'MessagesTemplatesResource', 'MessagesTemplatesService', 'templateEntity', 'id', '$state', 'toaster',
    function ($scope, MessagesTemplatesResource, MessagesTemplatesService, templateEntity, id, $state, toaster) {
        $scope.AUTOMATIC_CATEGORY_ID = 1;

        if (templateEntity == null) {
            $scope.data = {};
            $scope.data.category = null;
        } else {
			$scope.data = angular.copy(templateEntity.data);
		}

        $scope.page = 'template';
        $scope.label = 'Szablon';

        $scope.saving = false;
        $scope.save = function () {
            $scope.saving = true;

            $scope.data.categoryId = $scope.data.category.id;

            if (templateEntity == null) {
		        MessagesTemplatesResource.save($scope.data, function (response) {
		        	$scope.saving = false;
		            if (response.success == true) {
		                toaster.pop('success', 'Dane zapisane');
		                return $state.go('templates')
		            } else {
		                toaster.pop('error', response.error);
		            }
		        });
			} else {
	            MessagesTemplatesResource.update({ id: $scope.data.id }, $scope.data, function (response) {
	                $scope.saving = false;
	                if (response.success == true) {
                        toaster.pop('success', 'Dane uaktualnione');
                        return $state.go('templates');
	                } else {
	                    toaster.pop('error', response.error);
	                }
	            });
			}
        };

        $scope.categories = [];
        $scope.categoriesLoading = false;
        $scope.searchCategories = function (searchTxt) {
            $scope.categoriesLoading = true;
            MessagesTemplatesService.getCategories(searchTxt, true).then(function (response) {
                if (response.success == true) {
                    $scope.categories = response.data;
                    $scope.categoriesLoading = false;
                }
            });
        };
        $scope.searchCategories('');
    }
]);