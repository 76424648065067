(function () {
  'use strict';

  angular.module('app.export')
    .factory('ExportResource', ExportResource);

  ExportResource.$inject = ['$resource'];

  function ExportResource($resource) {
    return $resource('/export', null, {
      generate: {
        method: 'POST'
      }
    });
  }
})();