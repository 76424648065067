users.controller('AccountToDeleteController', ['$scope', 'registerResource', 'registerEntity', 'toaster', 'companyResource', 'companyService', 'messagesResource', 'MessagesTemplatesService',
    function ($scope, registerResource, registerEntity, toaster, companyResource, companyService, messagesResource, MessagesTemplatesService) {

		const TEMPLATE_CATEGORY_ID = 2;

		$scope.group = 'single';
		$scope.toClose = true;
	    $scope.disabledForm = false;
	    $scope.saving = false;
	    $scope.editV = { s: false };
	    $scope.data = { documents: [] };
	    $scope.message = null;

	    $scope.close = close;
        $scope.resetRequest = resetRequest;
        $scope.assign = assign;

	    if (registerEntity != null) {
	        $scope.register = angular.copy(registerEntity.data);
	    }

	    function close ()
	    {
            companyResource.delete({ id: $scope.register.id }, function (response) {
                if (response.success) {
                    toaster.pop('success', 'Konto zamknięte');
                    return $state.go('users.deletes');
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }

        function resetRequest()
        {
            var data = angular.copy($scope.register);
            data.status = 1;
            registerResource.update({ id: $scope.register.id, action: 'update' }, data, function (response) {
                if (response.success) {
                    toaster.pop('success', 'Prośba anulowana');
                    return $state.go('users.deletes');
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }

        function assign()
        {
            companyService.assign($scope.register.id, 'close').then(function (response) {
                $scope.register.responsible_close = $scope.loggedUser;
            });
        }

	    $scope.sendMessage = function () {
	        if ($scope.message == null) {
	            return toaster.pop('warning', 'Wpisz wiadomość');
	        }

	        var data = {
	            from: $scope.loggedUser,
	            to: $scope.register.users[0],
	            message: $scope.message,
	            group: $scope.group
	        };

	        messagesResource.send(data, function (response) {
	            if (response.success == true) {
	                toaster.pop('success', 'Wiadomość wysłana');
	                $scope.message = '';
	                $scope.data.template = null;
	            }
	        });
	    };

		$scope.templates = [];
	    $scope.templatesLoading = false;
	    $scope.searchTemplates = function (searchTxt) {
	        $scope.templatesLoading = true;
	        MessagesTemplatesService.getTemplates(searchTxt, TEMPLATE_CATEGORY_ID).then(function (response) {
	            if (response.success == true) {
	                $scope.templates = response.data;
	                $scope.templatesLoading = false;
	            }
	        });
	    };
	    $scope.searchTemplates('');

	    $scope.setBodyTemplate = function (selectedItem) {
	        $scope.message = selectedItem.body;
	    };
	}
]);