parameters.service('ParametersService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'LOGGED_USER', 'SU_TYPES',
function ($http, uiGridConstants, moment, gridService, LOGGED_USER, SU_TYPES) {
    var self = this;

    self.resource = null;
    self.gridApi = null;
    self.scope = null;
    self.resourceParams = {};

    var gridColumns = [
        {
            name: 'Nazwa',
            field: 'name',
            fieldType: 'text'
        },
        {
            name: 'Wartość',
            field: 'value',
            fieldType: 'text',
            width: '100',
            cellTemplate: function () {
                return '<div class="ui-grid-cell-contents" ng-if="row.entity.type == \'integer\'">{{ row.entity.value }}</div>' +
                    '<div class="ui-grid-cell-contents" ng-if="row.entity.type == \'boolean\'">{{ row.entity.value == 1 ? \'Tak\' : \'Nie\' }}</div>'
            },
        }
    ];

    var actionsColumn = {
        name: 'Akcje',
        fieldType: 'text',
        width: '100',
        enableFiltering: false,
        enableSorting: false,
        enableColumnMenu: false,
        cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center">' +
            '<a href="#/parameters/edit/{{ row.entity.key | lowercase }}" class="btn btn-primary btn-xs">Edycja</a>' +
        '</div></div>'
    };

    self.getGridOptions = function () {
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();
        gridOptions.columnDefs = self.getGridColumns();

        //add actions column for app and it admin
        if ([SU_TYPES.su_app, SU_TYPES.su_it].indexOf(LOGGED_USER.suType) !== -1) {
            gridOptions.columnDefs.push(actionsColumn);
        }

        return gridOptions;
    };

    self.getGridColumns = function () {
        return gridColumns;
    };

    self.getPage = function () {
        return gridService.getPage();
    };

    return self;
}]);