register.controller('TechnicianSubscriptionController', ['$scope', 'provincesResource', 'techniciansSubscriptionsResource', 'technicianEntity', '$state', 'toaster', function ($scope, provincesResource, techniciansSubscriptionsResource, technicianEntity, $state, toaster) {

    $scope.pattern = /^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹA-Z\s\-'"]*$/;

    $scope.data = {};
    $scope.data.certificate = null;
    $scope.data.certificateCountry = { id: 'PL', name: 'Polska' };

    if (technicianEntity != null) {
        $scope.data = technicianEntity.data;

        if ($scope.data.certificateCountryId) {
			$scope.data.certificateCountry = {
				id: $scope.data.certificateCountryId,
			};
		} else {
			$scope.data.certificateCountry = { id: 'PL', name: 'Polska' };
		}
    }

    $scope.loadingContent = true;
    $scope.$on('$includeContentLoaded', function(event) {
        $scope.loadingContent = false;
    });

    $scope.sendingForm = false;

    $scope.save = function () {
        $scope.sendingForm = true;

        techniciansSubscriptionsResource.update({ id: $scope.data.id }, $scope.data, function (response) {
            $scope.sendingForm = false;
            if (response.success == true) {
            	toaster.pop('success', 'Serwisant został zaktualizowany');
            	$state.go('technicians');
            } else {
            	toaster.pop('error', response.error);
            }
        });
    };

    $scope.closeForm = function () {
        $state.go('technicians');
    };
}]);