
notices.factory('NoticeUsersResource', ['$resource', function($resource) {
    return $resource('/notice-users/:action/:id', { action: '@action', id: '@id' }, {
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    })
}]);