common.service('provincesService', [function () {
    var provinces = [
        {"name": "dolnośląskie"},
        {"name": "kujawsko-pomorskie"},
        {"name": "lubelskie"},
        {"name": "lubuskie"},
        {"name": "łódzkie"},
        {"name": "małopolskie"},
        {"name": "mazowieckie"},
        {"name": "opolskie"},
        {"name": "podkarpackie"},
        {"name": "podlaskie"},
        {"name": "pomorskie"},
        {"name": "śląskie"},
        {"name": "świętokrzyskie"},
        {"name": "warmińsko-mazurskie"},
        {"name": "wielkopolskie"},
        {"name": "zachodniopomorskie"}
    ];

    //dla zapewnienia zgodnosci wstecz
    var provincesWithoutNames = [
		"",
        "dolnośląskie",
        "kujawsko-pomorskie",
        "lubelskie",
        "lubuskie",
        "łódzkie",
        "małopolskie",
        "mazowieckie",
        "opolskie",
        "podkarpackie",
        "podlaskie",
        "pomorskie",
        "śląskie",
        "świętokrzyskie",
        "warmińsko-mazurskie",
        "wielkopolskie",
        "zachodniopomorskie"
    ];


    var service = {
        getProvinces: getProvinces,
        getProvincesWithoutNames: getProvincesWithoutNames
    };

    return service;


    function getProvinces() {
        return provinces;
    }

    function getProvincesWithoutNames() {
        return provincesWithoutNames;
    }


}]);