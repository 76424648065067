parameters.controller('ParametersEditController', ['$scope', 'ParametersResource', 'parameterEntity', '$state', 'toaster',
    function ($scope, ParametersResource, parameterEntity, $state, toaster) {
        $scope.data = parameterEntity.data;
        $scope.saving = false;
        $scope.save = function () {
            $scope.saving = true;

            ParametersResource.update({ key: $scope.data.key }, $scope.data, function (response) {
                $scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', 'Dane uaktualnione');
                    return $state.go('parameters');
                } else {
                    toaster.pop('error', response.error);
                }
            });
        };
    }
]);