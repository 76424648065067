users.controller('AdminChangeController', ['$scope', 'userEntity', 'registerResource', 'messagesResource', 'toaster', '$state', 'usersService', 'provincesService', 'companyService', 'MessagesTemplatesService', 'USER_STATUS', '$http',
    function ($scope, userEntity, registerResource, messagesResource, toaster, $state, usersService, provincesService, companyService, MessagesTemplatesService, USER_STATUS, $http) {

		const TEMPLATE_CATEGORY_ID = 3;

        $scope.USER_STATUS = USER_STATUS;
        $scope.user = userEntity.data;
        $scope.register = {};
        $scope.getFiles = getFiles;
        $scope.sendMessage = sendMessage;
        $scope.activate = activate;
        $scope.edit = edit;
        $scope.cancel = cancel;
        $scope.save = save;
        $scope.sendWelcomeMessage = sendWelcomeMessage;
        $scope.deny = deny;
        $scope.assign = assign;

        $scope.data = {
            documents: []
        };

        $scope.editV = {
            s: false
        };

        $scope.provinces = provincesService.getProvincesWithoutNames();

        $scope.temporaryAdmin = [];
		$scope.currentAdmin = [];

		$scope.companyInfo = {};

        usersService.getTemporaryAdmin(userEntity.data.id).then(function (response) {
            $scope.temporaryAdmin = response;
            $scope.register.temporaryAdmin = angular.copy($scope.temporaryAdmin);
        });

		usersService.getCurrentAdmin(userEntity.data.companyId).then(function (response) {
            $scope.currentAdmin = response;
            $scope.register.currentAdmin = angular.copy($scope.currentAdmin);
        });

		usersService.getToken(userEntity.data.id).then(function (response) {
            //$scope.remember_token = response;
            $scope.register.remember_token = response.remember_token; //angular.copy($scope.remember_token);
        });

        var self = this;
        self.files = [];
        self.data = {
            from: $scope.loggedUser,
            to: userEntity.data,
            message: null,
            group: 'change_admin'
        };

        $scope.url = '/companies/' + userEntity.data.company.id + '/users/' + userEntity.data.id + '/files';

        $scope.register = angular.copy(userEntity.data.company);
        $scope.register.files = [];
        $scope.register.users = [];
        $scope.register.users.push(angular.copy(userEntity.data));
        $scope.message = null;


        getFiles();
        getCompany();
        //getCompanyFiles();


        function activate() {

            usersService.setNewAdmin(userEntity.data.id).then(function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Konto aktywowane");
                    return $state.go('users.adminChange');
                } else {
                    toaster.pop('error', "Błąd aktywacji");
                    console.warn(response);
                }
            });

        }


        function edit() {
            $scope.editV.s = true;
        }


        function getFiles() {
            usersService.getFiles(userEntity.data.id).then(function (response) {
                if (response.success == true) {
                    $scope.register.files = response.data;
                    self.files = response.data;
                } else {
                    console.warn("Blad pobierania plikow", response);
                }
            });
        }

        function getCompanyFiles () {
        	companyService.getFiles(userEntity.data.company.id).then(function (response) {
                if (response.success) {
                	$scope.register.files = response.data;
                	self.files = response.data;
                }
            });
        }

        function sendMessage() {
            if ($scope.message == null) {
                return toaster.pop('warning', "Wpisz wiadomość");
            }

            self.data.message = $scope.message;

            messagesResource.send(self.data, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                    $scope.message = '';
                    $scope.data.template = null;
                }
            });


        }

        function cancel() {
            $scope.register = angular.copy(userEntity.data.company);
            $scope.register.users = [];
            $scope.register.users.push(angular.copy(userEntity.data));
            $scope.register.files = self.files;
            $scope.editV.s = false;
            $scope.register.temporaryAdmin = angular.copy($scope.temporaryAdmin);
        }

        function sendWelcomeMessage() {
            registerResource.get({id: $scope.register.users[0].id, action: 'sendMessage'}, function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Wiadomość wysłana");
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }


        function save(formData) {

            var data = angular.copy(formData);

            if (data.temporaryAdmin != null) {
                usersService.updateTemporaryAdmin(data.temporaryAdmin).then(function(data) {
                    $scope.temporaryAdmin = data;
                    $scope.register.temporaryAdmin = angular.copy($scope.temporaryAdmin);
                });

                data.users = null;
            }


            registerResource.update({id: $scope.register.id, action: 'update'}, data, function (response) {
                if (response.success == true) {
                    $scope.editV.s = false;
                    userEntity.data.company = response.data;

                    if (response.data.remember_token_url) {
                    	$scope.user.remember_token_url = response.data.remember_token_url;
                    }
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }

        function deny () {
            usersService.denyNewAdmin(userEntity.data.id, $scope.temporaryAdmin).then(function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Prośba odrzucona");
                    return $state.go('users.adminChange');
                } else {
                    toaster.pop('error', "Błąd");
                    console.warn(response);
                }
            });
        }

        function assign()
        {
            companyService.assign($scope.register.id, 'adminChange').then(function (response) {
                $scope.companyInfo.responsible_admin_change = $scope.loggedUser;
            });
        }

        function getCompany()
        {
            companyService.getCompany(userEntity.data.company.id).then(function (response) {
                $scope.companyInfo = response.data;
            });
        }

        $scope.templates = [];
        $scope.templatesLoading = false;
        $scope.searchTemplates = function (searchTxt) {
            $scope.templatesLoading = true;
            MessagesTemplatesService.getTemplates(searchTxt, TEMPLATE_CATEGORY_ID).then(function (response) {
                if (response.success == true) {
                    $scope.templates = response.data;
                    $scope.templatesLoading = false;
                }
            });
        };
        $scope.searchTemplates('');

        $scope.setBodyTemplate = function (selectedItem) {
            $scope.message = selectedItem.body;
        };

        $scope.removeDocumentFile = function (index, name) {
            $http.get('/files/delete/' + name).then(function(response) {
                if (response.data.success == true) {
                    $scope.register.files.splice(index, 1);
                    toaster.pop('success', 'Plik usunięty');
                } else {
                    toaster.pop('error', 'Błąd usuwania pliku');
                }
            });
        };
    }]);