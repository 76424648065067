
devices.directive('dateValidate', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.dateValidate = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) && viewValue.length == 0 && attrs.ngRequired) {
                    return false;
                }

                if (modelValue && modelValue instanceof Date) {
    				var days = 1000 * 60 * 60 * 24;
    				var maxDate = new Date();
    				if (scope.maxDate) {
    					maxDate = scope.maxDate;
    				}
    				maxDate.setHours(0, 0, 0, 0);
    				modelValue.setHours(0, 0, 0, 0);
    				if (parseInt(modelValue.getTime() / days) > parseInt(maxDate.getTime() / days)) {
    					return false;
    				} else {
    					return true;
    				}
    			}

                return !attrs.ngRequired;
            };
        }
    };
});