auth.controller('LoginController', ['$scope', '$http', 'Session',  function ($scope, $http, Session) {

    $scope.data = {
        email: '',
        password: ''
    };


    $scope.errors = null;

    $scope.login = function (data) {
        $scope.errors = null;
        $http.post('/users/login', data).then(function (response) {

            if (response.data.success == true) {
                Session.create(response.data.data.token);
                window.location.href = '/main/#/dashboard';
            } else {
                $scope.errors = response.data.error;
            }


            //return response;
        });

    };


}]);