/**
 * Created by jgolebiewski on 3.11.2015.
 */
dictionaries.factory('dictionariesResource', ['$resource', function ($resource) {
    return $resource('/chemicals/:id/:action', {id: '@id', action: '@action'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    });
}]);