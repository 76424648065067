notifications.controller('LoginHistoryListController', ['$scope', 'historyService', function ($scope, historyService) {
    var self = this;

    $scope.listName = 'LoginHistoryList';

    historyService.scope = $scope;
    $scope.gridOptions = historyService.getGridOptions();

    historyService.getActions().then(function(data){
        $scope.gridOptions.columnDefs[3].filter.options = data;
    });

    historyService.getPage();


}]);

