/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var reports = angular.module("reports", [
    "ui.router",
]);

reports.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
		.state('reports', {
            url: "/reports",
            templateUrl: appRoot+ "/reports/views/list.html",
            controller: "ReportsController",
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            ncyBreadcrumb: {
                label: 'Raporty'
            }
        });

}]);