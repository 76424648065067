
var removed = angular.module('removed', [
    'ui.router', 'roles'
]);

removed.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider
        .state('removed', {
            url: '/removed-data',
            views: {
                '@': {
                    templateUrl: appRoot + 'removed/views/data.html',
                    controller: 'RemovedOldDataController'
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
            },
            ncyBreadcrumb: {
                label: 'Usunięte dane'
            }
        });
}]);