/**
 * Created by jgolebiewski on 08.08.2016.
 */

(function () {

    'use strict';

    angular.module('devices').service('devicesPermissionService', devicesPermissionService);

    devicesPermissionService.$inject = ['gridService', 'devicesResource'];

    function devicesPermissionService(gridService, devicesResource) {

        var vm = this;

        vm.scope = null;
        vm.resourceParams = null;

        vm.gridColumns = [
            {
                name: 'Id',
                field: "id",
                fieldType: 'number',
                visible: false
            },
            // {
            //     name: 'Imię i nazwisko',
            //     width: '*',
            //     field: "contact_surname",
            //     fieldType: 'text',
            //     cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            // },			
            {
                name: 'Imię i nazwisko',
                width: '*',
                field: "contact_surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },			
            {
                name: 'Urządzenie',
                field: "devices.name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Numer seryjny',
                field: "serialNumber",
                fieldType: 'text'
            },
            {
                name: 'Data dodania',
                field: 'devices.created_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Miasto (eksploatacji)',
                field: 'devices.exploitation_city',
                fieldType: 'text',
                enableFiltering: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.exploitation_city }}</div>'
            },
            {
                name: 'Adres (eksploatacji)',
                field: 'devices.exploitation_street',
                fieldType: 'text',
                enableFiltering: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity | formatAddress }}</div>'
            },

            {
                name: "Wybierz",
                fieldType: 'text',
                width: '100',
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,				
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center"><button data-ng-click="grid.appScope.addToList(row.entity)" class="btn btn-primary btn-sm btn-addall">Wybierz</button></div></div>'
            }
        ];


        var service = {
            getGridOptions: getGridOptions,
            getPage: getPage,
            setScope: setScope
        };

        return service;


        function setScope(scope) {
            vm.scope = scope;
        }

        function getGridOptions() {
            gridService.resource = devicesResource;
            gridService.scope = vm.scope;
            gridService.resourceParams = vm.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = vm.gridColumns;
            return gridOptions;
        }

        function getPage(type) {
        	if (typeof type !== 'undefined' && type) {
        		gridService.query.type = type;
        	}

            return gridService.getPage();
        }
    }

})();