/**
 * Created by jgolebiewski on 04.11.2015.
 */

messages.factory('messagesResource',['$resource', function($resource){
    return $resource('/messages/:action', { action: '@action'}, {
        send: {
            method: 'POST',
            params: {
                action: 'send'
            }
        },
        restorePassword: {
            method: 'POST',
            params: {
                action: 'sendNewPassword'
            }
        },
        getSendersList: {
            method: 'GET',
            params: {
                action: 'senders'
            }
        }
    })
}]);