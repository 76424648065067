/**
 * Created by jgolebiewski on 19.10.2015.
 */
devices.factory('devicesResource', ['$resource', function ($resource) {
    return $resource('/devices/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'}, {
        update: {
            method: 'PUT'
        },
        checkAccess: {
            method: 'POST',
            action: 'checkAccess'
        }
    });
}]);