notices.controller('NoticesListController', ['$scope', 'NoticesResource', 'NoticesService', 'uiGridConstants', '$uibModal', 'toaster',
    function ($scope, NoticesResource, NoticesService, uiGridConstants, $uibModal, toaster) {
		$scope.listName = 'NoticesList';

		NoticesService.scope = $scope;
		NoticesService.resource = NoticesResource;
        $scope.gridOptions = NoticesService.getGridOptions();
        NoticesService.getPage();

        $scope.itemToDelete = null;

        $scope.delete = function () {
			return NoticesResource.delete({ id: $scope.itemToDelete.id }).$promise.then(function (response) {
		        if (response.success == true) {
					$scope.cancel();
					NoticesService.getPage();
		            toaster.pop('success', 'Usunięto');
		            return response.data;
		        } else {
	                toaster.pop('error', response.error);
	            }
		    });
        };

		$scope.cancel = function () {
            $scope.itemToDelete = null;
            $scope.modalInstance.dismiss('cancel');
        };

        $scope.showDeleteModal = function (itemToDelete) {
            $scope.itemToDelete = itemToDelete;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                animation: true,
                backdrop: 'static',
                keyboard: false,
                scope: $scope
            });
        };
    }
]);