/**
 * Created by jgolebiewski on 03.09.2016.
 */
(function () {

    'use strict';

    angular.module('news').controller('NewsController', newsController);

    newsController.$inject = ['$scope', '$state', 'newsService', 'newsEntity'];

    function newsController($scope, $state, newsService, newsEntity) {
        var type = $state.params.type;
        var id = $state.params.id;

        $scope.data = {
            type: type,
            user: $scope.loggedUser.id,
            status: 0,
            documents: []
        };


        if (type == undefined && newsEntity != null) {
            type = newsEntity.type;
            $scope.data = angular.copy(newsEntity);

            if (newsEntity.type == 2) {
                $scope.data.documents = [];
                var docs = {
                    'name': newsEntity.originalFileName,
                    'systemFileName': newsEntity.systemFileName,
                    'originalFileName': newsEntity.originalFileName
                };

                $scope.data.documents.push(docs);
            }
        }



        $scope.template = newsService.getFormTemplate(type);
        $scope.title = newsService.getTitle(type);

        $scope.loadingContent = true;
        $scope.$on('$includeContentLoaded', function (event) {
            $scope.loadingContent = false;
        });

        $scope.saving = false;


        $scope.save = save;
        $scope.deleteItem = deleteItem;


        function save() {
            $scope.saving = true;
            newsService.save($scope.data).then(function (data) {
               if (data !== false) {
                   return $state.go('news');
               }
            });
        }

        function deleteItem(id) {
            return newsService.deleteItem(id).then(function (data) {
                if (data !== false) {
                    return $state.go('news');
                }
            })
        }
    }

})();