/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var notifications = angular.module("notifications", [
    "ui.router","roles"
]);

notifications.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES',function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('notifications', {
            url: "/notifications",
            templateUrl: appRoot + "notifications/views/notifications.list.html",
            controller: "LoginHistoryListController",
            ncyBreadcrumb: {
                label: 'Zdarzenia'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
        .state('notifications-devices', {
            url: '/notifications/devices',
            templateUrl: appRoot + 'notifications/views/devices.list.html',
            controller: 'DeviceHistoryListController',
            ncyBreadcrumb: {
                label: 'Zmiany w kartach'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
}]);