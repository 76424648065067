devices.service('chemicalAddedTypesService', [function () {
    var items = [
    	{ id: 1, name: 'pierwotna' },
    	{ id: 2, name: 'poddana recyklingowi' },
    	{ id: 3, name: 'regenerowana' }
    ];

    function getList() {
        return items;
    }

    var service = {
    	getList: getList
    };

    return service;
}]);