App.factory('PopupsService', ['$uibModal', '$http', '$interval', 'messagesResource', 'localStorageService', 'AuthService', 'LOGGED_USER', 'USER_ROLES', function ($uibModal, $http, $interval, messagesResource, localStorageService, AuthService, LOGGED_USER, USER_ROLES) {
	var PopupsService = {};

	PopupsService.scope = {};

	PopupsService.setScope = function (scope) {
		PopupsService.scope = scope;
		PopupsService.scope.cancelNewMsgModal = PopupsService.cancelNewMsgModal;
		PopupsService.scope.cancelIsInactiveUserMsgModal = PopupsService.cancelIsInactiveUserMsgModal;
	};

	PopupsService.showNewMsg = function () {
		if (LOGGED_USER.role.name != USER_ROLES.su) {
			PopupsService.scope.totalMessages = localStorageService.get('totalMessages');
			//if (PopupsService.scope.totalMessages === null) {
				var params = { limit: 100 };
				params.incoming = true;

				messagesResource.get(params).$promise.then(function (response) {
					if (response.success == true) {
			    		if (response.total > 0 && (PopupsService.scope.totalMessages === null || response.total > PopupsService.scope.totalMessages)) {
			    			PopupsService.scope.totalMessages = response.total;
			    			localStorageService.set('totalMessages', PopupsService.scope.totalMessages);
			    			PopupsService.openNewMsgModal();
			    		}
			        }
	            });
			//}
		}
	};

	PopupsService.openNewMsgModal = function () {
		PopupsService.scope.newMsgModal = $uibModal.open({
    		templateUrl: appRoot + '/messages/views/modals/new.message.modal.html',
    		size: 'large',
    		animation: true,
    		backdrop: 'static',
    		keyboard: false,
    		scope: PopupsService.scope,
    	});
    };

    PopupsService.cancelNewMsgModal = function () {
    	PopupsService.scope.newMsgModal.dismiss('cancel');
    };

    PopupsService.showIsInactiveUserMsg = function () {
		if (LOGGED_USER.role.name != USER_ROLES.su) {
			PopupsService.scope.timeLeftToLogout = localStorageService.get('timeLeftToLogout');
			if (PopupsService.scope.timeLeftToLogout === null) {
				if (typeof PopupsService.scope.timeInterval === 'undefined' || PopupsService.scope.timeInterval === null) {
					PopupsService.scope.timeInterval = $interval(function() {
						PopupsService.isActiveUser(PopupsService.initTimeLeftToLogout);
					}, 10000);
				}
			} else {
				PopupsService.setTimeLeftToLogout();
			}
		}
	};

	PopupsService.initTimeLeftToLogout = function (response) {
		if (!response) {
			if (typeof PopupsService.scope.timeInterval !== 'undefined' && PopupsService.scope.timeInterval !== null) {
				$interval.cancel(PopupsService.scope.timeInterval);
			}
			PopupsService.scope.timeInterval = null;
			if (PopupsService.scope.timeLeftToLogout === null) {
				PopupsService.scope.timeLeftToLogout = 30;
			}
			localStorageService.set('timeLeftToLogout', PopupsService.scope.timeLeftToLogout);
			PopupsService.openIsInactiveUserMsgModal();
        }
    };

    PopupsService.setTimeLeftToLogout = function () {
		if (PopupsService.scope.timeLeftToLogout === null) {
			PopupsService.scope.timeLeftToLogout = 30;
		}

		if (typeof PopupsService.scope.timeInterval === 'undefined' || PopupsService.scope.timeInterval === null) {
			PopupsService.scope.timeInterval = $interval(function() {
				PopupsService.scope.timeLeftToLogout = localStorageService.get('timeLeftToLogout');
				PopupsService.scope.timeLeftToLogout = PopupsService.scope.timeLeftToLogout - 1;
				localStorageService.set('timeLeftToLogout', PopupsService.scope.timeLeftToLogout);
				if (PopupsService.scope.timeLeftToLogout == 0) {
					$interval.cancel(PopupsService.scope.timeInterval);
					PopupsService.scope.timeInterval = null;
					PopupsService.scope.timeLeftToLogout = null;
					localStorageService.set('timeLeftToLogout', PopupsService.scope.timeLeftToLogout);
					AuthService.logout();
				}
			}, 1000);
		}
    };

	PopupsService.openIsInactiveUserMsgModal = function () {
		PopupsService.scope.isInactiveUserMsgModal = $uibModal.open({
    		templateUrl: appRoot + '/authentication/views/modals/logout.message.modal.html',
    		size: 'large',
    		animation: true,
    		backdrop: 'static',
    		keyboard: false,
    		scope: PopupsService.scope,
    	});
    };

    PopupsService.cancelIsInactiveUserMsgModal = function () {
    	PopupsService.scope.isInactiveUserMsgModal.dismiss('cancel');

    	PopupsService.setTimeLeftToLogout();
    };

    PopupsService.isActiveUser = function (handler) {
        return $http.get('/users/is-active').then(function (response) {
            if (response.data.success) {
            	handler(response.data.data);
            }
        });
    };

	return PopupsService;
}]);