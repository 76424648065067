/**
 * Created by jgolebiewski on 03.12.2015.
 */

(function () {

    'use strict';


angular.module("App")
    .filter('trustedHtml', ['$sce', function ($sce) {
        return function (text) {
            return $sce.trustAsHtml(text);
        };
    }])
    .filter('formatAddress', function () {
        return function (full) {
            if (full == undefined || full == '') {
                return ''
            }


            var string = full.exploitation_street;

            string += ' ' + full.exploitation_homeNumber;
            if (full.exploitation_flatNumber !== undefined && full.exploitation_flatNumber !== null && full.exploitation_flatNumber.length > 0) {
                string += ' / ' + full.exploitation_flatNumber;
            }

            // string += ', ' + full.exploitation_postcode;
            // string += ' ' + full.exploitation_city;
            return string;


        }
    })
    .filter('newsStatusFilter', function () {
       return function (input) {
           return input == 0 ? 'Nie opublikowwany' : 'Opublikowany';
       }
    })
    .filter('nameFilter', function () {
        return function (input, defaultUser) {
            if (input == undefined) {
                return defaultUser != undefined ? defaultUser : '';
            }

            if (input.hasOwnProperty('firstName') && input.hasOwnProperty('surname')) {
                return input.firstName + ' ' + input.surname;
            }

            return input;
        }
    })
    .filter('dateFromString', ['$filter', function ($filter) {
        return function (input, format) {
            if (input == undefined) {
                return ''
            }

            if (format == undefined) {
                format = 'MM/dd/yyyy';
            }
            return $filter('date')(new Date(input),format);
        }
    }]);
})();