
App.directive('ngLoader', ['$rootScope',
    function($rootScope) {
        return {
            link: function(scope, element, attrs) {


                element.addClass('hide');


                $rootScope.$on('$stateChangeStart', function() {
                    element.removeClass('hide');
                });


                $rootScope.$on('$stateChangeSuccess', function() {
                    element.addClass('hide');
                    $('body').removeClass('page-on-load');
                });

                $rootScope.$on('$viewContentLoaded', function() {
                    //console.log("content loaded");
                });

                // handle errors
                $rootScope.$on('$stateNotFound', function() {
                    element.addClass('hide');
                });

                $rootScope.$on('$stateChangeError', function() {
                    element.addClass('hide');
                });
            }
        };
    }
]);