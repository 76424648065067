/**
 * Created by jgolebiewski on 17.12.2015.
 */
common.directive('passwordChecker', function() {
    return {
        require: 'ngModel',
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function(password) {

               var characterGroupCount = checkPasswordContent(password);

                ctrl.$setValidity('complexity', characterGroupCount);

                if (characterGroupCount == true) {
                    return password;
                }

                return undefined;

            });
        }
    }
});