users.controller('SuListController', ['$scope','$http','suService', 'suResource', 'rolesResource', 'usersService', 'SU_TYPES', function ($scope, $http, suService, suResource, rolesResource, usersService, SU_TYPES) {

	$scope.listName = 'SuList';

    $scope.dupa = 'dupa';
    suService.scope = $scope;
    suService.resource = suResource;
    $scope.gridOptions = suService.getGridOptions();
    $scope.suTypes = SU_TYPES;

    $scope.canEdit = suService.canEdit;

    $scope.hasPasswordExpired = function(date) {
        return (moment(date) < moment());
    };
    
    rolesResource.getSuTypes(function (response) {
        
        var suTypes = [];
        angular.forEach(response.data, function(suType){
                suTypes.push({
                    id: suType,
                    label: suType 
                });
        });

        usersService.suTypes = suTypes;

        var index = 2;

        $scope.gridOptions.columnDefs[index].filter.options = suTypes;
    });

    suService.getPage();

}]);