/**
 * Created by jgolebiewski on 19.10.2015.
 */
angular.module("news").factory('newsResource', ['$resource', function ($resource) {
    return $resource('/news/:id', {id: '@id'}, {
        update: {
            method: 'PUT'
        }
    });


}]);