notifications.service('historyService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'loginHistoryResource', 'toaster',
    function ($http, uiGridConstants, moment, gridService, loginHistoryResource, toaster) {
        var self = this;

        self.resource = loginHistoryResource;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var actionsTab = [
        ];


        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
           /* {
                name: "Podmiot",
                width: '*',
                minWidth: 200,
                field: "name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company.firstName == null"><a href="#/users/company/{{ row.entity.company_id }}" >{{ row.entity.company_name }}</a></div>' +
                '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company_firstName != null"><a href="#/users/company/{{ row.entity.company_id }}" >{{ row.entity.company_firstName }} {{ row.entity.company_surname }}</a></div>'
            },*/
            {
                name: 'Imię i nazwisko',
                width: '*',
                field: "surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },
            {
                name: 'Adres IP',
                field: "ip",
                fieldType: 'text',
            },
            {
                name: 'Nazwa urządzenia',
                field: "devices.name",
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: "Rodzaj czynności",
                //width: '120',
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'action',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: []
                }
            },
            {
                name: 'Data',
                field: 'login_history.created_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents text-center">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }} </div>'
            },
        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        self.getActions = getActions;

        function getActions() {
            return loginHistoryResource.actions().$promise.then(response);
        }


        function response(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn(response);
            }
        }

        return self;

    }]);