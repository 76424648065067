/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('companyResource', ['$resource', function ($resource) {
    return $resource('/companies/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
            	action: 'find'
            }
        },
        assign: {
            method: 'PUT',
            params: {
                action: 'assign',
                id: '@id'
            }
        },
    });
}]);