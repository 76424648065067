users.controller('CompanyHistoryController', ['$scope', 'companyHistoryService',
    function ($scope, companyHistoryService) {

		$scope.listName = 'CompanyHistoryList';

        $scope.$on('loadHistoryEvent', function(){
            getCompanyHistory();
        });

        companyHistoryService.scope = $scope;
        companyHistoryService.resourceParams = {id: $scope.$parent.data.id};
        $scope.gridOptions = companyHistoryService.getGridOptions();
        companyHistoryService.getPage();

        function getCompanyHistory() {

        }


    }]);