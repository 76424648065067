users.controller('CloseCompanyController', ['$scope', 'companyService','devicesService','toaster',
    function ($scope, companyService, devicesService, toaster) {

        $scope.canClose = false;
        $scope.checking = false;
        $scope.showCloseMessage = false;
        $scope.companyEntity = $scope.$parent.data;
        $scope.data = {};

        if ($scope.companyEntity.status == 2) {
            $scope.showCloseMessage = true;
        }

        $scope.sendRequest = sendRequest;


        $scope.$on('closeCompanyEvent', function(){
            $scope.checking = true;
            checkOpensCart();
        });


        function checkOpensCart() {
            devicesService.checkOpenCart().then(function(response){
                $scope.canClose = response.data.check;
                $scope.checking = false;
            });
        }

        function sendRequest() {
            companyService.sendCloseRequest($scope.companyEntity.id, $scope.data).then(function(data){
                $scope.companyEntity = data;
                    if (data.status == 2) {
                        $scope.showCloseMessage = true;
                    }
            });
        }



    }]);