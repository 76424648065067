/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('usersResource', ['$resource', function ($resource) {
    //return $resource('js/src/users/resources/users.json');
    return $resource('/users/:id/:action/:subId', {id: '@id', action: '@action', subId: '@subId'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    });
}]);