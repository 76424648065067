/**
 * Created by jgolebiewski on 03.09.2016.
 */
(function () {

    'use strict';

    angular.module('news').service('newsService', newsService);

    newsService.$inject = ['newsResource', 'toaster'];

    function newsService(newsResource, toaster) {
        var TYPE_NEWS = 1;
        var TYPE_DOCUMENT = 2;
        var TYPE_LINK = 3;



        var service = {
            getDocuments: getDocuments,
            getNews: getNews,
            getLinks: getLinks,
            getFormTemplate: getFormTemplate,
            getTitle : getTitle,
            save: save,
            get: get,
            deleteItem: deleteItem
        };

        return service;


        function getDocuments(params) {
            return getData(params, TYPE_DOCUMENT);
        }

        function getLinks(params) {
            return getData(params, TYPE_LINK);
        }

        function getNews(params) {
            return getData(params, TYPE_NEWS);
        }

        function getData(params, type) {

            params.type = type;

            return newsResource.get(params).$promise.then(checkResponse);
        }

        function save(data) {
            if (data.hasOwnProperty('id')) {
                return newsResource.update({id: data.id}, data).$promise.then(savedInfo);
            }

            return newsResource.save(data).$promise.then(savedInfo);
        }


        function savedInfo(response) {
            if (response.success == true) {
                toaster.pop('success', "Zapisane");
                return response.data;
            }

            toaster.pop('error', "Błąd zapisu");
            return false;
        }

        function checkResponse(response) {
            if (response.success == true) {
                return response;
            }

            console.info(response);
        }

        function checkResponseData(response) {
            if (response.success == true) {
                return response.data;
            }

            console.info(response);
        }

        function getFormTemplate(type) {
            var tpl = 'not-found.html';
            switch (type) {
                case 1:
                    tpl = 'form.news.html';
                    break;
                case 2:
                    tpl = 'form.document.html';
                    break;
                case 3:
                    tpl = 'form.link.html';
                    break;
            }

            return tpl;
        }

        function getTitle(type) {
            var tpl = 'not-found.html';
            switch (type) {
                case 1:
                    tpl = 'Nowe ogłoszenie';
                    break;
                case 2:
                    tpl = 'Nowy dokument';
                    break;
                case 3:
                    tpl = 'Nowy link';
                    break;
            }

            return tpl;
        }

        function get(id) {
            return newsResource.get({id: id}).$promise.then(checkResponseData);
        }

        function deleteItem(id) {
            return newsResource.delete({id: id}).$promise.then(deletedInfo);
        }

        function deletedInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Usunięto');
                return response.data;
            }

            return false;
        }
    }

})();