users.controller('UserHistoryController', ['$scope', 'userHistoryService',
    function ($scope, userHistoryService) {

		$scope.listName = 'UserHistoryList';

        $scope.$on('loadUserHistoryEvent', function(){
            getUserHistory();
        });

        userHistoryService.scope = $scope;
        userHistoryService.resourceParams = {id: $scope.$parent.data.id};
        $scope.grid2Options = userHistoryService.getGridOptions();
        userHistoryService.getPage();

        function getUserHistory() {

        }

    }]);