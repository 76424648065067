devices.service('companyDevicesService', ['$http', 'uiGridConstants', 'moment', 'gridService',function ($http, uiGridConstants, moment, gridService) {
    var self = this;

    self.resource = null;

    self.gridApi = null;

    self.scope = null;

    self.deviceCategories = [];

    self.resourceParams = {};

    var dateRangePickerOptions = {
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Wybierz",
            "cancelLabel": "Anuluj",
            "fromLabel": "Od",
            "toLabel": "Do",
            "customRangeLabel": "Zdefiniuj"
        },
        ranges: {
            'Dzisiaj': [moment(), moment()],
            'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
            'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
            'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
            'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    };


    var gridColumns = [

        {
            name: 'Urządzenie',
            field: "name",
            fieldType: 'text',
        },
        {
            name: 'Numer seryjny',
            field: "serialNumber",
            fieldType: 'text'
        },
        {
            name: 'Imię i nazwisko',
            width: '*',
            field: "surname",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.user.firstName }} {{ row.entity.user.surname }}</div>'
        },
        {
            name: 'Data dodania',
            field: 'created_at',
            fieldType: 'dateRange',
            enableFiltering: true,
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>'
        },
        {
            name: 'Data aktualizacji',
            field: 'updated_at',
            fieldType: 'dateRange',
            enableFiltering: true,
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>'
        },
        {
            name: "Status",
            width: '120',
            enableFiltering: true,
            enableSorting: false,
            enableColumnMenu: false,
            fieldType: 'text',
            field: 'status',
            cellTemplate: function () {
                return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Otwarta</div></div>' +
                    '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zamknięta</div></div>'
            },

            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
            '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
            '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            filter: {
                options: [{id: 0, label: 'Otwarta'}, {id: 1, label: 'Zamknięta'}]
            }
        },
        {
            name: "Edycja",
            fieldType: 'text',
            width: '100',
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center"><a href="#/devices/{{ row.entity.id }}" class="btn btn-primary btn-xs">Zobacz</a></div></div>'
        }
    ];

    self.getGridOptions = function () {
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();

        gridOptions.columnDefs = self.getGridColumns();
        return gridOptions;
    };

    self.getGridColumns = function () {
        return gridColumns;
    };

    self.getDateRangePickerOptions = function () {
        return dateRangePickerOptions;
    };

    self.getPage = function (){
        return gridService.getPage();
    };

    self.getXLS = function (companyId, params) {
    	var query = {};
    	if (typeof params !== 'undefined' && params) {
    		var index = 0;
    		angular.forEach(params, function (param, name) {
    			if (
    				(param === undefined || param === null || param === '') || (
    					(angular.isObject(param) && !angular.isArray(param) && (!param.startDate || !param.endDate)) ||
    					(angular.isArray(param) && param.length == 0)
    				)
    			) {
    				return true;
    			}
    			query['filters[' + index + '][field]'] = name;
    			if (!angular.isObject(param)) {
    				query['filters[' + index + '][search]'] = param;
    			}
    			else if (!angular.isArray(param)) {
    				query['filters[' + index + '][search][from]'] = param.startDate.format('YYYY-MM-DD');
    				query['filters[' + index + '][search][to]'] = param.endDate.format('YYYY-MM-DD');
    			} else {
    				angular.forEach(param, function (value, number) {
    					query['filters[' + index + '][search][' + number + ']'] = value;
    				});
    			}
    			index++;
    		});
    	}

    	return self.resource.get(query, { action: 'company-xls', subId: companyId }).$promise.then(function(response) {
    		if (response.success) {
    			return response.data.hash;
    		} else {
    			console.error('Błąd generowania raportu', response);
    			return false;
    		}
    	});
    };

    return self;

}]);