users.controller('RegistersListController', ['$scope','$http','registerService', 'registerResource', function ($scope, $http, registerService, registerResource) {

	$scope.listName = 'RegistersList';

    registerService.scope = $scope;
    registerService.resource = registerResource;
    $scope.gridOptions = registerService.getGridOptions();

    registerService.getPage();

}]);