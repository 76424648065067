/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('rolesResource', ['$resource', function ($resource) {
    var service = $resource('/roles');

    service.get = function($obj, callback) {
        return $resource('/roles').get($obj, callback);
    }
    service.getSuTypes = function(callback, suType) {
        if (typeof suType !== 'undefined') {
           return $resource('/roles/sutypes', {type: suType}).get({}, callback);
        }
        return $resource('/roles/sutypes').get({}, callback);
    }

    return service;
}]);