/**
 * Created by jgolebiewski on 24.07.2015.
 */
/*
 * Szablon modułu
 */


var dictionaries = angular.module("dictionaries", [
    "ui.router","roles", "ui.grid.edit"
]);

dictionaries.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES',function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('dictionaries', {
            url: "/dictionaries",
            abstract: true
        })

        .state('dictionaries.list', {
            url: "",
            views: {
                '@': {
                    templateUrl: appRoot + "dictionaries/views/dictionaries.list.html",
                    controller: "DictionariesListController",
                }
            },

            ncyBreadcrumb: {
                label: 'Słowniki'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })



}]);