notices.controller('NoticeController', ['$scope', 'NoticesResource', 'NoticesService', 'noticeEntity', 'id', '$state', 'toaster',
    function ($scope, NoticesResource, NoticesService, noticeEntity, id, $state, toaster) {
        if (noticeEntity == null) {
            $scope.data = {
            	is_active: 1
            };
        } else {
			$scope.data = angular.copy(noticeEntity.data);
			if ($scope.data.startDate) {
				$scope.data.startDate = new Date($scope.data.startDate);
			}
			if ($scope.data.endDate) {
				$scope.data.endDate = new Date($scope.data.endDate);
			}
		}

        $scope.page = 'notice';
        $scope.label = 'Komunikat';

        $scope.dateFormat = 'dd/MM/yyyy';

		$scope.dateRangePickerOptions = {
            'on-open-focus': true,
            'show-button-bar': false
        };

		$scope.date = {
            start: false,
            end: false
        };

		$scope.types = [
        	{ id: 0, name: 'Wszyscy' },
        	{ id: 1, name: 'Zarejestrowani' },
        ];

		$scope.openDatepicker = function(obj) {
            $scope.date[obj] = true;
        };

        $scope.saving = false;
        $scope.save = function () {
            $scope.saving = true;

            if ($scope.data.startDate) {
				$scope.data.startDate.setHours(12);
			}
			if ($scope.data.endDate) {
				$scope.data.endDate.setHours(12);
			}

            if (noticeEntity == null) {
            	NoticesResource.save($scope.data, function (response) {
		        	$scope.saving = false;
		            if (response.success == true) {
		                toaster.pop('success', 'Dane zapisane');
		                return $state.go('notices')
		            } else {
		                toaster.pop('error', response.error);
		            }
		        });
			} else {
				NoticesResource.update({ id: $scope.data.id }, $scope.data, function (response) {
	                $scope.saving = false;
	                if (response.success == true) {
                        toaster.pop('success', 'Dane uaktualnione');
                        return $state.go('notices');
	                } else {
	                    toaster.pop('error', response.error);
	                }
	            });
			}
        };
    }
]);