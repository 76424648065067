/**
 * Created by jgolebiewski on 29.10.2015.
 */
auth.service('Session', ['webStorage', function (webStorage) {

    this.create = function (sessionId) {
        webStorage.local.set('sid', sessionId);
    };

    this.destroy = function () {
        webStorage.local.clear();
    };

    this.addCart = function (id) {
        webStorage.local.set('cid', id);
    };

    this.getCart = function () {
        return webStorage.local.get('cid');
    };

}]);