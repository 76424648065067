devices.controller('DeviceController', ['$scope', 'devicesResource', 'deviceEntity', 'mode', 'moment', 'id', 'devicesService', '$state', 'toaster', 'Session','operationGridService','DateTimeService', 'companyService', 'provincesService', 'usersResource','$filter','$uibModal',
    function ($scope, devicesResource, deviceEntity, mode, moment, id, devicesService, $state, toaster, Session, operationGridService, DateTimeService, companyService, provincesService, usersResource, $filter, $uibModal) {

       $scope.reasonPattern = /(?=^[a-ząćęłńóśżźĄĆĘŁŃÓŚŻŹ0-9A-Z\-'".,\(\)áäčéëöőüž\s])(?=.*[a-zA-Z]).+$/;
       
        var vm = this;
        vm.deviceToDelete = null;
		
        $scope.isAuthorized = false;

        if (id != Session.getCart()) {
            toaster.pop('error', "Brak dostępu");
            return $state.go('devices.list');
        } else {
            $scope.isAuthorized = true;
        }

        if (deviceEntity == null) {
            return $state.go('devices.list');
        }

        $scope.isContactUser = $scope.loggedUser.role.name == $scope.userRoles.user;

        $scope.dateRangePickerOptions = {
            'on-open-focus': true,
            'show-button-bar': false
        };

        $scope.editDisabled = true;
        $scope.status = {opened: false};
        $scope.deviceCategories = [];
        $scope.catsLoading = false;
        $scope.unSelectable = false;
        $scope.closeReasons = devicesService.getCloseReasons();

        $scope.getDeviceCategoriesOne = getDeviceCategoriesOne;
        $scope.open = open;
        $scope.setMode = setMode;
        $scope.close = close;
        $scope.openCart = openCart;
        $scope.checkCategoriesTwo = checkCategoriesTwo;
		$scope.showDeleteModal = showDeleteModal;
		$scope.hide = hide;
		$scope.deleteDevice = deleteDevice;



        $scope.showClose = {s:false};

        $scope.data = angular.copy(deviceEntity.data);
        $scope.type = $scope.data.type;


        $scope.data.companyName = $scope.data.company.name != null ? $scope.data.company.name : $scope.data.company.firstName + ' ' + $scope.data.company.surname;

        $scope.data.user.email = $scope.data.user.deleted_at != null ? $scope.data.user.email.split("|")[0] : $scope.data.user.email;



        $scope.mode = (mode == undefined) ? 'preview' : mode;


        $scope.user = {
            username: $scope.loggedUser.email,
            password: ''
        };

        $scope.hideNewData = ((new Date($scope.data.createDate)).getTime() < (new Date('2024-03-20 17:00:00')).getTime());

        $scope.data.createDateBase = $scope.data.createDate;

        $scope.data.productionDate = DateTimeService.convertToMomentObj($scope.data.productionDate);
        $scope.data.createDate = moment($scope.data.createDate).format('DD/MM/YYYY');

        $scope.editDisabled = !$scope.data.company.device_exploitation_address_editable;

        getCurrentAdmin();

        $scope.getDeviceCategoriesOne($scope.data.chemical);

        checkCategoriesTwo($scope.data.category_one);


        $scope.dateFormat = 'dd/MM/yyyy';

        $scope.voivodeships = provincesService.getProvincesWithoutNames();

        $scope.contact = { users: [] };
        $scope.contacts = [];
        $scope.contacts.push($scope.data.operatorId);
        $.each($scope.data.users, function (index, user) {
			$scope.contacts.push(user.userId);
			$scope.data.users[index] = user.user || user;
			if (user.user) {
				$scope.data.users[index].firstname = user.user.firstName;
				$scope.data.users[index].userId = user.user.id;
				$scope.data.users[index].id = user.id;
			}
    	});

        $scope.changePageSize = function (value) {
            $scope.pageSize = value;
            $scope.getDevOperations();
        };

        $scope.pageSizes = operationGridService.pageSizes;
        $scope.paginationId = "paginationIdDevice";
        $scope.pageSize = operationGridService.defaultPageSize;
        $scope.currentPage = 1;
        $scope.total = 0;

        $scope.$watch('data.original_quantity', function (newValue, oldValue) {
        	if (newValue != oldValue) {
        		$scope.data.quantity = parseFloat(newValue || 0) + parseFloat($scope.data.installation_quantity || 0);
        	}
        });

        $scope.$watch('data.installation_quantity', function (newValue, oldValue) {
        	if (newValue != oldValue) {
        		$scope.data.quantity = parseFloat($scope.data.original_quantity || 0) + parseFloat(newValue || 0);
        	}
        });

        $scope.$watch('data.hermetically_sealed', function (newValue, oldValue) {
        	if (newValue == 0 && $scope.data.in_residential_building == 1) {
        		$scope.data.in_residential_building = 0;
        	}
        	if (newValue == 1) {
        		$scope.data.installation_quantity = 0;
        	}
        });


        $scope.check = function (){
            $scope.$broadcast("loadOper", true);
        };

        $scope.loadingOperations = false;
        $scope.getDevOperations = function () {
            $scope.loadingOperations = true;

            var params = {
                limit: $scope.pageSize,
                page: $scope.currentPage
            };

            devicesResource.get(params, {id: $scope.data.id, action: 'operations'}, function (response) {
                $scope.loadingOperations = false;
                if (response.success == true) {
                    $scope.operations = response.data;
                    $scope.total = response.total;

                    $scope.index = [];
                    $.each($scope.operations.operations, function (index, operation) {
            			$scope.operations.operations[index].index = $scope.getOperationIndex();

            			if (operation.type.indexOf('Likwidacja urządzenia') !== -1 && operation.liquidation_causes) {
            				operation.type = operation.type.replace('Likwidacja urządzenia', 'Likwidacja urządzenia (' + operation.liquidation_causes + ')');
            			}
                	});
                }
            });
        };

        $scope.getDevOperations();

        $scope.pageChanged = function (newPage) {
            $scope.currentPage = newPage;
            $scope.getDevOperations();
        };


        //temp
        //$scope.typeId=2;

        $scope.page = 'device';
        $scope.label = "Karta urządzenia";

        if ($scope.type == 2) {
            $scope.page = 'fire.protection';
            $scope.label = 'Karta Systemu Ochrony Przeciwpożarowej'
        }

        $scope.loadingContent = true;
        $scope.$on('$includeContentLoaded', function (event) {
            $scope.loadingContent = false;
        });

        $scope.getPage = function () {
            return appRoot + 'devices/views/pages/' + $scope.page + '.page.html';
        };


        $scope.saving = false;
        $scope.save = function () {
            $scope.saving = true;

            //$scope.data.createDate = deviceEntity.data.createDate;
            $scope.data.productionDate = $filter('date')($scope.data.productionDate, 'yyyy-MM-dd');
            $scope.data.categoryOne = $scope.data.category_one.id;
            $scope.data.categoryTwo = ($scope.data.category_two != undefined) ? $scope.data.category_two.id : null;
            $scope.data.chemicalId = $scope.data.chemical.id;

            /*
            $.each($scope.data.users, function (index, user) {
	        	if (
					user.userId && $scope.contact.users && $scope.contact.users[index] && (
						user.firstname != $scope.contact.users[index].firstName ||
			            user.surname != $scope.contact.users[index].surname ||
						user.email != $scope.contact.users[index].email ||
			    		user.phone != $scope.contact.users[index].phone
		    		)
				) {
					$scope.data.users[index].userId = null;
				}
        	});
            */

            devicesResource.update({id: $scope.data.id}, $scope.data, function (response) {
                $scope.saving = false;
                if (response.success == true) {
                    if ($scope.data.status == 1) {
                        toaster.pop('success', "Karta zamknięta");
                    } else {
                        toaster.pop('success', "Dane uaktualnione");
                    }

                    $scope.data.history = response.data.history;

                    $scope.mode = 'preview';
                } else {
                    toaster.pop('error', response.error);
                }
            });
        };


        $scope.searchChemicalTxt = '';
        $scope.chemicals = [];
        $scope.chemicalsLoading = false;
        $scope.searchChemicals = function (searchTxt) {

            if (searchTxt.length == 0) {
            	if ($scope.data.chemical && $scope.chemicals.length == 0) {
            		searchTxt = $scope.data.chemical.ashrae;
            	} else {
            		return false;
            	}
            }

            $scope.searchChemicalTxt = searchTxt;

            var type = null;
            var conditions = {
        		type: $scope.type,
        		category: $scope.data.category_one ? $scope.data.category_one.id : null,
        	};

            $scope.chemicalsLoading = true;
            devicesService.getChemicals(searchTxt, type, conditions).then(function (response) {
                if (response.success == true) {
                    $scope.chemicals = response.data;
                    $scope.chemicalsLoading = false;

                    if ($scope.data.chemical) {
                    	$.each($scope.chemicals, function (index, chemical) {
                    		if (chemical.id == $scope.data.chemical.id && chemical.subjectToRegistration != $scope.data.chemical.subjectToRegistration) {
                    			$scope.data.chemical.subjectToRegistration = chemical.subjectToRegistration;
                    			return false;
                    		}
                    	});
                    }
                }
            });
        };

        $scope.clientsLoaded = false;
        $scope.searchUser = function(searchTxt) {
            if (searchTxt.length == 0) {
                return false;
            }

            var data = {};
            data['filters[0][field]'] = 'users.surname';
            data['filters[0][search]'] = searchTxt;

            $scope.clientsLoaded = true;

            usersResource.search(data, function(response) {
                if (response.success == true) {
                    $scope.users = response.data;
                    $scope.clientsLoaded = false;
                }
            });
        };

        $scope.addContactUser = function () {
        	if ($scope.data.users.length >= 2) {
        		return;
        	}

            $scope.data.users.push({
                firstname: null,
                surname: null,
				email: null,
        		phone: null,
            });
        };

        $scope.removeContactUser = function ($index) {
        	var index = $scope.contacts.indexOf($scope.data.users[$index].userId);
            if (index !== -1) {
            	$scope.contacts.splice(index, 1);
            }

            $scope.data.users.splice($index, 1);
        };

        $scope.setContactUser = function ($index, $item) {
        	if ($scope.contacts.indexOf($item.id) !== -1) {
        		return;
        	}
        	if ($scope.data.users[$index] && $scope.data.users[$index].userId) {
	        	var index = $scope.contacts.indexOf($scope.data.users[$index].userId);
	            if (index !== -1) {
	            	$scope.contacts.splice(index, 1);
	            }
        	}

            $scope.data.users[$index] = {
            	id: $scope.data.users[$index].id || null,
            	userId: $item.id,
                firstname: $item.firstName,
                surname: $item.surname,
				email: $item.email,
        		phone: $item.phone,
            };

            $scope.contacts.push($item.id);
        };


        function getDeviceCategoriesOne (selectedItem) {

            if (selectedItem == undefined) {
                $scope.deviceCategories = [];
                $scope.data.category_one = null;
                $scope.data.category_two = null;
                return;
            }

            $scope.catsLoading = true;

            devicesService.getCategories(selectedItem.type, $scope.type, selectedItem.id).then(function (response) {
                $scope.catsLoading = false;
                if (response.success == true) {
                    $scope.deviceCategories = response.data;
                }
            });

        }

        function open($event) {
            $scope.status.opened = true;
        }


       function setMode (mode) {
            $scope.mode = mode;
            if (mode == 'preview') {
            	$scope.data = angular.copy(deviceEntity.data);
            }
       }


        function close() {
            $scope.data.status = 1;
            $scope.showClose = {s:false};
            $scope.data.closeReason = devicesService.getCloseReasonById($scope.data.closeReason);
            $scope.save();
        }

        function openCart () {
            $scope.data.status = 0;
            $scope.save();
        }

        function checkCategoriesTwo ($selectedItem) {

            if (devicesService.checkCategoriesTwo($selectedItem) == true) {
                $scope.data.category_two = null;
                $scope.unSelectable = true;
            } else {
                $scope.unSelectable = false;
            }

        }

		function showDeleteModal(itemToDelete, list) {
            vm.deviceToDelete = itemToDelete;
           /* vm.listToReload = list;*/
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/devices/views/delete.device.modal.html',
                // size: 'od-lg',
                animation: true,
                backdrop: 'static',
                keyboard: false,
                scope: $scope
            });
        }
		
        function hide() {
            /*vm.listToReload = null;*/
            vm.deviceToDelete = null;
            $scope.modalInstance.dismiss('cancel');
        }
		
		function deleteDevice() {
            return devicesService.deleteDevice(vm.deviceToDelete).then(function (data) {
                if (data !== false) {
                    //getList(vm.listToReload);
                    hide();
					toaster.pop('success', 'Usunięto');
                }
            })
        }

		function getCurrentAdmin() {
			if (!$scope.data.admin) {
	            companyService.getAdmin($scope.data.company.id).then(function(data) {
	                $scope.data.admin = data;
	            });
			}
        }


		$scope.getDevice = function () {
			devicesResource.get({ id: $scope.data.id }, function (response) {
	        	if (response.success == true) {
	        		$scope.data = angular.extend($scope.data, response.data);
	        	}
	        });
		};

		$scope.clearChemical = function ($event, $select) {
			$event.stopPropagation();
			$select.selected = null;
			//$select.search = undefined;
			//$select.activate();

			$scope.deviceCategories = [];
		    $scope.data.category_one = null;
		    $scope.data.category_two = null;
		}

		$scope.clearCategory = function ($event, $select) {
			$event.stopPropagation();
			$select.selected = null;
			//$select.search = undefined;
			//$select.activate();

			$scope.data.category_one = null;
			$scope.data.category_two = null;

			$scope.searchChemicals($scope.searchChemicalTxt);
		}

		$scope.quantityMin = function () {
			if ($scope.data.chemical) {
				if ($scope.data.chemical.type == 'FGC' || $scope.data.chemical.type == 'MIX') {
					if ($scope.oldLimit()) {
						return 3;
					}
					if ($scope.data.hermetically_sealed == 1 && $scope.data.chemical.leakage_test_by_quantity == 1 && parseFloat($scope.data.chemical.subjectToRegistration) < 2) {
						return 2;
					}
					return $scope.data.chemical.subjectToRegistration || 0;
				}
				else if ($scope.data.chemical.type == 'SZWO') {
					return 3;
				}
			} else {
				return 0;
			}
		}

		$scope.oldLimit = function () {
			if ((new Date($scope.data.createDateBase)).getTime() < (new Date('2018-02-01')).getTime()) {
				if (parseFloat($scope.data.chemical.subjectToRegistration) > 3 && parseFloat($scope.data.quantity) < parseFloat($scope.data.chemical.subjectToRegistration)) {
					return true;
				}
			}
			return false;
		}

		$scope.checkEquivalent = function () {
			if ($scope.hideNewData) return false;

			return (
				$scope.type == 1 &&
				$scope.data.hermetically_sealed == 1 &&
				$scope.data.chemical.gwp &&
				$scope.data.chemical.leakage_test_by_quantity != 1 &&
				(((($scope.data.quantity || 0) * $scope.data.chemical.gwp) / 1000).toFixed(0)) < 10
			);
		};

		$scope.inResidentialBuildingRequired = function () {
			if ($scope.hideNewData) return false;

			var categories = [9, 13];
			var categoryTwo = ($scope.data.category_two != undefined ? $scope.data.category_two.id : 0);

			return ($scope.type == 1 && $scope.data.hermetically_sealed == 1 && parseFloat($scope.data.quantity) < 3 && categoryTwo && categories.indexOf(categoryTwo) != -1);
		};

		$scope.getOperationIndex = function () {
        	var index = $scope.index && $scope.index.length ? $scope.index.length : 0;
        	if (index > 0) {
	        	$scope.index[index] = $scope.index[index - 1];
	        	if ($scope.operations.operations[index].inputId != $scope.operations.operations[index - 1].inputId) {
	        		$scope.index[index]++;
	        	}
        	} else {
        		$scope.index = [1];
        	}

        	return $scope.index[index];
        };

        $scope.formInvalid = function () {
        	if ($scope.hideNewData) return false;

        	return ($scope.type == 1 && (
        		($scope.data.chemical.gwp && !$scope.oldLimit() && $scope.checkEquivalent()) ||
        		($scope.inResidentialBuildingRequired() && $scope.data.in_residential_building == 1)
        	));
        };
    }]);

