/**
 * Created by mkaleta on 28.04.2016.
 */
users.factory('tokenResource', ['$resource', function ($resource) {
    //return $resource('js/src/users/resources/users.json');
    return $resource('/users/:action/:id/:subId', {id: '@id', action: '@action', subId: '@subId'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    });
}]);