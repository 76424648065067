(function () {
  'use strict';

  angular.module('app.stats')
    .factory('StatsResource', StatsResource);

  StatsResource.$inject = ['$resource'];

  function StatsResource($resource) {
    return $resource('/stats', {startDate: '@startDate', endDate: '@endDate'}, {
      generate: {
        method: 'GET'
      }
    });
  }
})();