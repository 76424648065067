notices.service('NoticesService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'NoticesResource', function ($http, uiGridConstants, moment, gridService, NoticesResource) {
    var self = this;

    self.resource = null;
    self.gridApi = null;
    self.scope = null;
    self.resourceParams = {};

    var gridColumns = [
        {
            name: 'Tytuł',
            field: 'title',
            fieldType: 'text',
        },
        {
            name: 'Data rozpoczęcia',
            field: 'startDate',
            fieldType: 'dateRange',
            enableFiltering: true,
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.startDate | amDateFormat: \'DD/MM/YYYY\' }}</div>'
        },
		{
            name: 'Data zakończenia',
            field: 'endDate',
            fieldType: 'dateRange',
            enableFiltering: true,
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.endDate | amDateFormat: \'DD/MM/YYYY\' }}</div>'
        },
		{
            name: 'Status',
            width: '120',
            enableFiltering: true,
            enableSorting: false,
            enableColumnMenu: false,
            fieldType: 'text',
            field: 'is_active',
            cellTemplate: function () {
                return '<div class="ui-grid-cell-contents" ng-if="row.entity.is_active == 0"><div class="label label-danger">Nieaktywny</div></div>' +
                    '<div class="ui-grid-cell-contents" ng-if="row.entity.is_active == 1"><div class="label label-success">Aktywny</div></div>'
            },
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
            '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
            '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            filter: {
                options: [{id: 0, label: 'Nieaktywny'}, {id: 1, label: 'Aktywny'}]
            }
        },
        {
            name: 'Akcje',
            fieldType: 'text',
            width: '200',
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center">' +
            	'<a href="#/notices/edit/{{ row.entity.id }}" class="btn btn-primary btn-xs">Edycja</a>' +
            	'<a href data-ng-click="grid.appScope.showDeleteModal(row.entity)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></a>' +
            '</div></div>'
        }
    ];

    self.getGridOptions = function () {
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();
        gridOptions.columnDefs = self.getGridColumns();

        return gridOptions;
    };

    self.getGridColumns = function () {
        return gridColumns;
    };

    self.getPage = function () {
        return gridService.getPage();
    };

    return self;
}]);