dictionaries.service('dictionariesService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'chemicalsResource', 'LOGGED_USER', 'USER_ROLES',
    function ($http, uiGridConstants, moment, gridService, chemicalsResource, LOGGED_USER, USER_ROLES) {
        var self = this;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [

            {
                name: 'Nazwa chemiczna',
                width: '*',
                field: "chemicalName",
                fieldType: 'text',
                //enableCellEdit: true,
            },
            {
                name: 'Nazwa handlowa',
                width: '*',
                field: "name",
                fieldType: 'text',
                //enableCellEdit: true
            },
            {
                name: 'Ashrae',
                width: '*',
                field: "ashrae",
                fieldType: 'text',
                //enableCellEdit: true
            },
            /*
            {
                name: 'Mieszanina',
                width: '*',
                field: "components",
                fieldType: 'text',
                enableCellEdit: true
            },
            */
            {
                name: 'GWP',
                width: '*',
                field: 'gwp',
                fieldType: 'text',
                //enableCellEdit: true
            },

            {
                name: "Typ",
                width: '10%',
                //minWidth: 150,
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'type',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 'SZWO', label: 'SZWO'}, {id: 'FGC', label: 'FGC'}, {id: 'MIX', label: 'MIX'}]
                },
                //enableCellEdit: true,
                editDropdownValueLabel: 'label',
                editDropdownOptionsArray: [{id: 'SZWO', label: 'SZWO'}, {id: 'FGC', label: 'FGC'}, {id: 'MIX', label: 'MIX'}],
                editableCellTemplate: 'ui-grid/dropdownEditor'
            },

            {
                name: "Edycja",
                fieldType: 'text',
                width: '12%',
                //minWidth: 150,
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                //enableCellEdit: true,
                cellTemplate: '<div class="ui-grid-cell-contents">' +
                '<button type="button" data-ng-click="grid.appScope.setData(row.entity); grid.appScope.showForm(true)" class="btn btn-default btn-xs">Edytuj</button>&nbsp;' +
                '<button type="button" data-ng-click="grid.appScope.open(row.entity.id)" class="btn btn-warning btn-xs"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></button>&nbsp;' +
                '</div>'
            }
        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.queryParams.hidden = 1;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            if (LOGGED_USER.role.name != USER_ROLES.su && gridColumns[0].name == 'Podmiot') {
                gridColumns.splice(0, 1);
            }
            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };

        self.getChemicals = function (searchText) {
        	var query = {};
        	query.subId = searchText;
        	query.hidden = 1;

            return chemicalsResource.search(query, { action: 'find' }).$promise;
        };

        return self;

    }]);