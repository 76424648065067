
messages.factory('MessagesTemplatesCategoriesResource', ['$resource', function($resource) {
    return $resource('/messages-templates-categories/:action/:id', { action: '@action', id: '@id' }, {
    	update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    })
}]);