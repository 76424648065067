/**
 * Created by jgolebiewski on 14.09.2016.
 */

(function () {

    'use strict';

    angular.module('common').service('countriesService', countriesService);

    countriesService.$inject = ['countriesResource'];

    function countriesService(countriesResource) {
        var service = {
            getCountries:getCountries
        };

        return service;

        function getCountries(type) {
            if (type == 'ue') {
                return countriesResource.getUE().$promise.then(getData);
            }

            if (type == 'nonUE') {
                return countriesResource.getNonUE().$promise.then(getData);
            }

            return countriesResource.getAll().$promise.then(getData);
        }

        function getData(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn("Błąd zapytania", response);
            }
        }
    }

})();