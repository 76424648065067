users.controller('CompanyChangeAdminController', ['$scope', 'companyService','usersService','toaster','remindResource',
    function ($scope, companyService, usersService, toaster, remindResource) {

        var companyEntity = $scope.$parent.data;

        $scope.loadingCurrentAdmin = false;
        $scope.currentAdmin = {};
        $scope.users = [];
        $scope.companyStatus = companyEntity.status;

        $scope.showForm = {s: false};
        $scope.closeForm = closeForm;

        $scope.data = {
            status: 0
        };

        $scope.intiForm = function (form) {
            $scope.userForm = form;
        };

        $scope.setAdmin = setAdmin;

        if ($scope.companyStatus != 3) {
            getNonAdmins();
        }

        $scope.$on('loadAdminAndUsers', function(){
            if ($scope.companyStatus != 3) {
                $scope.loadingCurrentAdmin = true;
                getCurrentAdmin();
            }

        });



        var sendEmail = function () {
            remindResource.save({email: $scope.data.email}, function (response) {

                if (response.success == true) {
                    toaster.pop('success', "Wiadomośc z hasłem wysłana");

                } else {
                    toaster.pop('error', response.error);
                }
            });
        };

        $scope.save = function () {
            $scope.data.role = {id: 2};
            $scope.data.companyId = companyEntity.id;
            $scope.saving = true;
            usersService.save($scope.data).then(function (response) {
                $scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został dodany");
                    sendEmail();
                    closeForm();
                    setAdmin(response.data.id);
                } else {
                    toaster.pop('error', response.error);
                }
            });

        };





        function getCurrentAdmin() {
            companyService.getAdmin(companyEntity.id).then(function(data){
                $scope.loadingCurrentAdmin = false;
                $scope.currentAdmin = data;
            });
        }

        function getNonAdmins() {
            companyService.getNonAdmins(companyEntity.id).then(function(data){
               $scope.users = data;
            });
        }

        function setAdmin (userId) {
            usersService.setNewAdmin(userId).then(function (response) {
                if (response.success == true) {
                    toaster.pop('success', "Konto aktywowane");
                    getCurrentAdmin();
                    getNonAdmins();
                } else {
                    console.warn(response);
                }
            });

        }

       function closeForm () {
            $scope.showForm.s = false;
            $scope.data = {
                status: 0
            };
            $scope.userForm.$setSubmitted(true);
            $scope.userForm.$setUntouched(true);
        };


    }]);