/**
 * Created by jgolebiewski on 3.11.2015.
 */
notifications.factory('loginHistoryResource', ['$resource', function ($resource) {
    //return $resource('js/src/users/resources/users.json');
    return $resource('/loginHistory/:id/:action', {id: '@id', action: '@action'},{
        update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        actions: {
            method: 'GET',
            params: {
                action: 'actions',
            }
        }
    });
}]);