(function() {
  'use strict';

  angular.module('app.stats')
    .service('StatsService', StatsService);

  StatsService.$inject = ['StatsResource'];

  function StatsService(StatsResource) {
    return {
      getStatsData: getStatsData
    };

    function getStatsData(params) {
      return StatsResource.get(params).$promise;
    }

    function parseData(response) {
      if (response.success == true) {
        return response.data;
      } else {
        console.error("Błąd zapytania", response);
      }
    }
  }
})();