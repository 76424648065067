devices.service('operationGridService', ['devicesResource', function (devicesResource) {

    var self = this;

    self.pageSizes = [
        {name: '10', value: 10},
        {name: '20', value: 20},
        {name: '50', value: 50},
    ];

    self.defaultPageSize = 10;


    return self;

}]);