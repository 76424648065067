
var devices = angular.module("devices", [
    "ui.router",'daterangepicker','roles', 'angularUtils.directives.dirPagination'
]);

devices.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES',function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider

        .state('devices', {
            url: "/devices",
            abstract: true
        })

        .state('devices.list', {
            url: "",
            views: {
                '@': {
                    templateUrl: appRoot + "/devices/views/devices.list.html",
                    controller: "DevicesListController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            },
            ncyBreadcrumb: {
                label: 'Lista urządzeń'
            }
        })

        .state('devices.list.company', {
            url: "/company/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "devices/views/company.devices.list.html",
                    controller: "CompanyDevicesListController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Lista urządzeń dla podmiotu'
            },
            resolve: {
                companyId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                companyEntity: ['companyId', 'companyResource', function (companyId, companyResource) {
                    return companyResource.get({id: companyId}).$promise;
                }]
            }
        })

        .state('devices.list.checkAccess', {
            url: "/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "devices/views/device.access.html",
                    controller: "DeviceAccessController"
                }
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Urządzenie'
            },
            resolve: {
                id: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })

        .state('devices.list.edit', {
            url: "/edit/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "devices/views/device.html",
                    controller: "DeviceController"
                }
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Urządzenie'
            },
            resolve: {
                mode: function() {
                    return 'preview'
                },
                id: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                deviceEntity: ['$stateParams','devicesResource', function ($stateParams, devicesResource) {
                    return devicesResource.get({id: $stateParams.id}).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })

        .state('devices.list.new', {
            url: "/new/{type:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "devices/views/new.device.html",
                    controller: "NewDeviceController"
                }
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Nowe urządzenie'
            },
            resolve: {
                mode: function() {
                    return 'new'
                },
                type: ['$stateParams', function ($stateParams) {
                    return $stateParams.type;
                }],
                deviceEntity: function () {
                    return null
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.user, USER_ROLES.admin]
            }
        })

        .state('devices.multiplePermissions', {
            url: "/change-permissions",
            views: {
                '@': {
                    templateUrl: appRoot + "devices/views/devices.permission.html",
                    controller: "DevicesPermissionController"
                }
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Zmiana uprawnien do wielu kart'
            },

            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
            }
        })

        .state('devices.multiplePdf', {
            url: '/multiple-pdf',
            views: {
                '@': {
                    templateUrl: appRoot + 'devices/views/devices.pdf.html',
                    controller: 'DevicesPdfController'
                }
            },
            ncyBreadcrumb: {
                parent: 'devices.list',
                label: 'Pobierz PDF wielu kart'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })


}]);