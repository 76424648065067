/**
 * Created by jgolebiewski on 19.10.2015.
 */
common.factory('countriesResource', ['$resource', function ($resource) {
    return $resource('/country/:action/:searchTxt/ue/:param', {action: '@action', searchTxt: '@searchTxt', param: '@param'}, {
        getUE: {
            method: 'GET',
            params: {
                param: 1,
                action: 'find',
                searchTxt: 'null'
            }
        },
        getNonUE: {
            method: 'GET',
            params: {
                param: 0,
                action: 'find',
                searchTxt: 'null'
            }
        },
        getAll: {
            method: 'GET',
            params: {
                param: 2,
                action: 'find',
                searchTxt: 'null'
            }
        }

    });
}]);