/**
 * Created by jgolebiewski on 08.08.2016.
 */

(function () {

    'use strict';

    angular.module('devices').controller('DevicesPermissionController', DevicesPermissionController);

    DevicesPermissionController.$inject = ['$scope', 'usersResource', '$uibModal', 'devicesPermissionService', 'toaster', 'devicesResource'];


    function DevicesPermissionController($scope, usersResource, $uibModal, devicesPermissionService, toaster, devicesResource) {

        var vm = this;

        vm.modalInstance = null;

        $scope.clientsLoaded = false;
        $scope.data = {
            devices: []
        };

        devicesPermissionService.setScope($scope);
        $scope.gridOptions = devicesPermissionService.getGridOptions();
        $scope.changeAccessDevices = changeAccessDevices;
        $scope.openModal = openModal;
        $scope.addToList = addToList;
		$scope.addAllToList = addAllToList;
        $scope.searchUser = searchUser;
        $scope.cancel = cancel;		
        $scope.removeDevice = removeDevice;


        /**
         * Zapis kart
         * @param data
         */
        function changeAccessDevices(data) {
            $scope.saving = true;
            devicesResource.save({ action: 'set-permissions'}, data,function(response){
                $scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Dostęp nadany");
                    clearData();
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }


        /**
         * Wyczyszczenie danych formularza
         */
        function clearData() {
            $scope.data = {
                devices: []
            }
        }


        /**
         * Dodanie karty do listy kart do zmiany uprawnien
         * @param device
         * @returns {boolean}
         */
        function addToList(device) {

            if (Utils.inArray(device, $scope.data.devices)) {
                toaster.pop('warning', 'Karta już dodana');
                return false;
            }
            toaster.pop('info', 'Karta dodana');
            $scope.data.devices.push(device)
        }

		/**
         * Dodanie wszystkich widocznych kart do listy kart do zmiany uprawnien         
         * @returns {boolean}
         */
        function addAllToList() {			
			for (var i = 0, len = $scope.gridOptions.data.length; i < len; i++) {
				addToList($scope.gridOptions.data[i]);
			}
			/*
            if (Utils.inArray(scope, $scope.data.devices)) {
                toaster.pop('warning', 'Karta już dodana');
                return false;
            }
            toaster.pop('info', 'Karta dodana');
            $scope.data.devices.push(device)
			*/
        }

        /**
         * Wyszukiwanie użytkownika do przypisania
         * @param searchTxt
         * @returns {boolean}
         */
        function searchUser(searchTxt) {

            if (searchTxt.length == 0) {
                return false;
            }


            var string = {};
            string['filters[0][field]'] = 'users.surname';
            string['filters[0][search]'] = searchTxt;

            $scope.clientsLoaded = true;
            usersResource.search(string, function (response) {
                if (response.success == true) {
                    $scope.users = response.data;
                    $scope.clientsLoaded = false;
                }
            });
        }


        /**
         * Modal z listą kart
         */
        function openModal() {
            $scope.gridOptions = devicesPermissionService.getGridOptions();

            vm.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/devices/views/modal.devices.html',
                size: 'xxl',
                scope: $scope,
                resolve: {
                    items: function () {
                        devicesPermissionService.getPage();
                    }
                }
            })

        }

        function cancel() {
            vm.modalInstance.dismiss('cancel');
        }		
		
        /**
         * Usuwanie karty z listy kart do zmiany uprawnien
         * @param $index
         */
        function removeDevice($index) {
            $scope.data.devices.splice($index, 1);
        }


        $scope.addAccessDevices = function(data) {
        	data.action = 'add';
        	$scope.changeAccessDevices(data);
        };

        $scope.removeAccessDevices = function(data) {
        	data.action = 'remove';
        	$scope.changeAccessDevices(data);
        };

        $scope.getDevicesByType = function (type) {
        	devicesPermissionService.getPage(type);
        };
    }

})(appRoot, Utils);