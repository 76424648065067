users.controller('TechniciansSubscriptionsListController', ['$scope', '$http', 'techniciansSubscriptionsService', 'techniciansSubscriptionsResource', '$uibModal', 'toaster', function ($scope, $http, techniciansSubscriptionsService, techniciansSubscriptionsResource, $uibModal, toaster) {

	$scope.listName = 'TechniciansSubscriptionsList';

    techniciansSubscriptionsService.scope = $scope;
    techniciansSubscriptionsService.resource = techniciansSubscriptionsResource;
    $scope.gridOptions = techniciansSubscriptionsService.getGridOptions();

    techniciansSubscriptionsService.getPage();

    $scope.delete = function () {
		return techniciansSubscriptionsResource.delete({ id: $scope.itemToDelete.id }).$promise.then(function (response) {
	        if (response.success == true) {
				$scope.cancel();
				techniciansSubscriptionsService.getPage();
	            toaster.pop('success', 'Usunięto');
	            return response.data;
	        } else {
                toaster.pop('error', response.error);
            }
	    });
    };

    $scope.cancel = function () {
        $scope.itemToDelete = null;
        $scope.modalInstance.dismiss('cancel');
    };

    $scope.showDeleteModal = function (itemToDelete) {
        $scope.itemToDelete = itemToDelete;
        $scope.modalInstance = $uibModal.open({
            templateUrl: appRoot + '/common/views/modals/delete.modal.html',
            animation: true,
            backdrop: 'static',
            keyboard: false,
            scope: $scope
        });
    };
}]);