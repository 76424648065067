    /**
 * Created by jgolebiewski on 24.07.2015.
 */


var users = angular.module("users", [
    "ui.router","roles",'summernote', 'register'
]);

users.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise("/dashboard");

    $stateProvider
        .state('users', {
            url: "/users",
            abstract: true
        })

        .state('users.list', {
            url: "",
            views: {
              '@': {
                  templateUrl: appRoot + "users/views/users.list.html",
                  controller: "UsersListController"
              }
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
            },
            ncyBreadcrumb: {
                label: 'Użytkownicy'
            }
        })

        .state('users.list.ByCompany', {
            url: "/company/{id:int}",
            views: {
              '@': {
                  templateUrl: appRoot + "users/views/company.users.list.html",
                  controller: "CompanyUsersListController"
              }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                label: 'Lista użytkowników dla podmiotu'
            },
            resolve: {
                companyId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                companyEntity: ['companyId', 'companyResource', function (companyId, companyResource) {
                    return companyResource.get({id: companyId}).$promise;
                }]
            }
        })

        .state('users.list.companyProfile', {
            url: "/company/{id:int}/profile",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/company.profile.html",
                    controller: "CompanyController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
            },
            ncyBreadcrumb: {
                label: 'Profil firmy'
            },
            resolve: {
                companyEntity: ['$stateParams', 'companyResource', '$state', '$rootScope', function ($stateParams, companyResource, $state, $rootScope) {
                    if ($rootScope.loggedUser.role.name == USER_ROLES.su) {
                        return companyResource.get({id: $stateParams.id}).$promise;
                    }

                    var id = $rootScope.loggedUser.company.id;

                    return companyResource.get({id: id}).$promise;

                }]
            }
        })

        .state('users.edit', {
            url: "/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/user.html",
                    controller: "UserController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.list',
                label: 'Edycja użytkownika'
            },
            resolve: {
                userId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                userEntity: ['userId', 'usersResource', function (userId, usersResource) {
                    return usersResource.get({id: userId}).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
            }
        })

        .state('users.profile', {
            url: "/profile/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/user.profile.html",
                    controller: "UserProfileController"
                }
            },
            ncyBreadcrumb: {
                parent: 'dashboard',
                label: 'Profil użytkownika'
            },
            data: {
                authorizedRoles: [USER_ROLES.su, USER_ROLES.admin, USER_ROLES.user]
            }
        })

        .state('users.new', {
            url: "/new",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/user.html",
                    controller: "UserController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users',
                label: 'Nowy użytkownik'
            },
            resolve: {
                userId: function () {
                    return null;
                },
                userEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.admin]
            }
        })

        .state('users.registers', {
            url: "/registers",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/registers.list.html",
                    controller: "RegistersListController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                parent: 'users.list',
                label: 'Lista kont do aktywacji'
            }
        })

        .state('users.registers.edit', {
            url: "/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/register.html",
                    controller: "RegisterEditController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.registers',
                label: 'Aktywacja konta'
            },
            resolve: {
                userId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                registerEntity: ['userId', 'registerResource', function (userId, registerResource) {
                    return registerResource.show({id: userId}).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('users.adminChange', {
            url: "/adminChange",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/adminChange.list.html",
                    controller: "AdminChangeListController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                parent: 'users.list',
                label: 'Zmiana Administratora'
            }
        })

        .state('users.adminChange.edit', {
            url: "/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/adminChange.verify.html",
                    controller: "AdminChangeController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.adminChange',
                label: 'Weryfikacja nowego Administratora'
            },
            resolve: {
                userId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                userEntity: ['userId', 'usersResource', function (userId, usersResource) {
                    return usersResource.get({id: userId}).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('users.deletes', {
            url: "/toclose",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/accounts.todelete.html",
                    controller: "AccountsToDeleteController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.list',
                label: 'Lista kont do zamknięcia'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('users.deletes.verify', {
            url: '/{id:int}',
            views: {
                '@': {
                    templateUrl: appRoot + 'users/views/register.html',
                    controller: 'AccountToDeleteController'
                }
            },
            ncyBreadcrumb: {
                parent: 'users.deletes',
                label: 'Weryfikacja konta do zamknięcia'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            resolve: {
                userId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                registerEntity: ['userId', 'registerResource', function (userId, registerResource) {
                    return registerResource.show({ id: userId }).$promise;
                }]
            }
        })
		
		.state('users.su', {
            url: "/su",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/su.list.html",
                    controller: "SuListController"
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            },
            ncyBreadcrumb: {
                parent: 'users.list',
                label: 'Lista kont wewnętrznych'
            }
        })
        .state('users.su.edit', {
            url: "/{id:int}",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/su.html",
                    controller: "SuController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.su',
                label: 'Edycja użytkownika wewnętrznego'
            },
            resolve: {
                userId: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                userEntity: ['userId', 'usersResource', function (userId, usersResource) {
                    return usersResource.get({id: userId}).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
        .state('users.su.new', {
            url: "/su/new",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/su.html",
                    controller: "SuController"
                }
            },
            ncyBreadcrumb: {
                parent: 'users.su',
                label: 'Nowy użytkownik wewnętrzny'
            },
            resolve: {
                userId: function () {
                    return null;
                },
                userEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
        .state('technicians', {
            url: "/technicians",
            views: {
                '@': {
                    templateUrl: appRoot + "users/views/technicians.subscriptions.list.html",
                    controller: "TechniciansSubscriptionsListController"
                }
            },
            ncyBreadcrumb: {
                parent: '',
                label: 'Zarejestrowani serwisanci'
            },
            resolve: {
                userId: function () {
                    return null;
                },
                userEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
        .state('technicians.edit', {
            url: '/edit/{id:int}',
            views: {
                '@': {
                    templateUrl: appRoot + 'users/views/technician.subscription.html',
                    controller: 'TechnicianSubscriptionController'
                }
            },
            ncyBreadcrumb: {
                parent: 'technicians',
                label: 'Edycja serwisanta'
            },
            resolve: {
                id: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                technicianEntity: ['id', 'techniciansSubscriptionsResource', function (id, techniciansSubscriptionsResource) {
                    return techniciansSubscriptionsResource.get({ id: id }).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
}]);