auth.controller('ContactController', ['$scope','$http','Session','$window', function ($scope, $http, Session, $window) {

    $scope.data = {
        email: ''
    };

    $scope.success = false;

    $scope.errors = null;
    $scope.remind = function (data) {

        $scope.errors = null;
        
        $http.post('/messages/adminSend', data).then(function (response) {

            if (response.data.success == true) {
                $scope.success = true;

            } else {
                $scope.errors = response.data.data;
            }

            return false;
        });

    };


}]);