messages.service('MessagesTemplatesService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'MessagesTemplatesResource', 'MessagesTemplatesCategoriesResource', function ($http, uiGridConstants, moment, gridService, MessagesTemplatesResource, MessagesTemplatesCategoriesResource) {
    var self = this;

    self.resource = null;
    self.gridApi = null;
    self.scope = null;
    self.resourceParams = {};

    var gridColumns = [
        {
            name: 'Nazwa',
            field: 'subject',
            fieldType: 'text',
        },
        {
            name: 'Kategoria',
            field: 'category_name',
            fieldType: 'text',
            filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
            '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
            '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            filter: {
                options: {}
            }
        },
        {
            name: 'Akcje',
            fieldType: 'text',
            width: '200',
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center">' +
            	'<a href="#/templates/edit/{{ row.entity.id }}" class="btn btn-primary btn-xs">Edycja</a>' +
            	'<a href data-ng-click="grid.appScope.showDeleteModal(row.entity)" data-ng-disabled="row.entity.categoryId == grid.appScope.AUTOMATIC_CATEGORY_ID" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></a>' +
            '</div></div>'
        }
    ];

    self.getGridOptions = function () {
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();
        gridOptions.columnDefs = self.getGridColumns();

        return gridOptions;
    };

    self.getGridColumns = function () {
        return gridColumns;
    };

    self.getPage = function () {
        return gridService.getPage();
    };

    self.getTemplates = function (searchText, categoryId, withoutAutomatic) {
    	var data = {
            limit: 100
        };
    	if (searchText.length > 0) {
    		data = {
	        	'filters[0][field]': 'subject',
	            'filters[0][search]': searchText,
	            limit: 100
	        };
    	}
    	if (typeof categoryId !== 'undefined') {
    		data['filters[1][field]'] = 'categoryId';
	        data['filters[1][search]'] = categoryId;
        }
        if (typeof withoutAutomatic !== 'undefined') {
            data.withoutAutomatic = withoutAutomatic;
        }
    	return MessagesTemplatesResource.search(data).$promise;
    };

    self.getCategories = function (searchText, withoutAutomatic) {
    	var data = {
            limit: 100
        };
    	if (searchText.length > 0) {
    		data = {
	        	'filters[0][field]': 'name',
	            'filters[0][search]': searchText,
	            limit: 100
	        };
        }
        if (typeof withoutAutomatic !== 'undefined') {
            data.withoutAutomatic = withoutAutomatic;
        }
    	return MessagesTemplatesCategoriesResource.search(data).$promise;
    };

    return self;
}]);