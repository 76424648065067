users.controller('CompanyUsersListController', ['$scope', 'companyService', 'usersResource', 'rolesResource', 'companyEntity', 'companyId',
    function ($scope, companyService, usersResource, rolesResource, companyEntity, companyId) {

		$scope.listName = 'CompanyUsersList';

        $scope.company = companyEntity.data;

        companyService.scope = $scope;
        companyService.resourceParams = {action: 'users', id: companyId};
        $scope.gridOptions = companyService.getGridOptions();


        rolesResource.get({}, function (response) {

            var roles = [];
            angular.forEach(response.data, function(role){

                roles.push({
                    id: role.id,
                    label: role.name == 'ADMIN' ? 'Administrator' : 'Użytkownik'
                })

            });

            companyService.roles = roles;
            $scope.gridOptions.columnDefs[5].filter.options = roles;
        });



        companyService.getPage();
    }]);