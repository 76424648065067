/**
 * Created by jgolebiewski on 16.12.2015.
 */

reports.controller('ReportsController', ['$scope', function ($scope) {


    var reports = [
        {
            url: '/reports/reportEntitiesNumber',
            title: 'Liczba operatorów',
        },
		{
            url: '/reports/reportEntitiesNumberPerChemical',
            title: 'Liczba operatorów w podziale na substancje',
        },
        {
            url: '/reports/reportDevicesNumber',
            title: 'Liczba urządzeń',
        },
		{
            url: '/reports/reportDevicesNumberPerChemical',
            title: 'Liczba urządzeń w podziale na substancje',
        },
        {
            url: '/reports/reportChemicalVolumes',
            title: 'Ilość substancji zawartych w urządzeniach',
        },
		{
            url: '/reports/reportChemicalVolumesPerChemical',
            title: 'Ilość substancji zawartych w urządzeniach w podziale na substancje',
        },
        {
            url: '/reports/reportRecycledVolumes',
            title: 'Ilość substancji odzyskanych/dodanych',
        },
		{
            url: '/reports/reportRecycledVolumesPerChemical',
            title: 'Ilość substancji odzyskanych/dodanych w podziale na substancje',
        },
    ];

    $scope.reports = reports;

}]);