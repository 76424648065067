/**
 * Created by jgolebiewski on 13.01.2016.
 */
devices.service('activitiesService', [function(){

    var service = {
        getActivities: getActivities
    };

    return service;

    function getActivities() {
        return [
            {id:1, name: 'Instalacja', type: 0},
            {id:2, name: 'Konserwacja lub serwisowanie',type: 0},
            {id:3, name: 'Odzysk', type: 0},
            {id:4, name: 'Kontrola szczelności', type: 0},
            { id: 5, name: 'Naprawa', type: 0 },
            { id: 6, name: 'Naprawa nieszczelności', type: 0 },
            { id: 7, name: 'Likwidacja urządzenia', type: 0 },
            { id: 8, name: 'Instalowanie systemu wykrywania wycieku', type: 0 },
            { id: 9, name: 'Kontrola systemu wykrywania wycieków', type: 0 },
        ]
    }

}]);