    /**
 * Created by jgolebiewski on 03.10.2014.
 */
common.factory('LoaderInterceptor', ['$q','$window' ,function($q, $window) {
    var notificationChannel;
    return {
        request: function(config) {
            return config;
        },
        requestError: function(rejection) {
            //console.log('Request error due to ', rejection);
            // Continue to ensure that the next promise chain
            // sees an error
            return $q.reject(rejection);
            // Or handled successfully?
            // return someValue;
        },
        response: function(response) {
            
             if (response.data.time_out == 'yes') {
                $window.location.href = "/";
                return false;
            }
            return response || $q.when(response);
        },
        responseError: function(rejection) {
            console.warn('Error in response ');

            if ((rejection.data.success == false && (rejection.status == '400' || rejection.status == '404')) || rejection.status == '419') {
                $window.location.href = "/";
                return false;
            }
            if (response.data.time_out == 'yes') {
                $window.location.href = "/";
                return false;
            }
            
            return $q.reject(rejection);
        }
    };
}]);

common.config(['$httpProvider', function($httpProvider) {  
    $httpProvider.interceptors.push('LoaderInterceptor');
}]);