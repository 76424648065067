/**
 * Created by mkaleta on 15.09.2016.
 */
users.factory('suResource', ['$resource', function ($resource) {
    return $resource('/users/su:action/:id', {action: '@action', id: '@id'}, {
        update: {
            method: 'PUT'
        },
        show: {
            params: {
                'action': 'show'
            }
        },
        get: {
            params: {
                'action': 'list'
            }
        }
    });
}]);