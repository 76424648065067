devices.service('devicesService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'deviceCategoriesResource', 'chemicalsResource', 'devicesResource', 'techniciansDataResource',
    function ($http, uiGridConstants, moment, gridService, deviceCategoriesResource, chemicalsResource, devicesResource, techniciansDataResource) {
        var self = this;

        self.authorizedId = null;

        self.resource = null;

        self.gridApi = null;

        self.scope = null;

        self.deviceCategories = [];

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };


        var gridColumns = [
            {
                name: "Podmiot",
                field: "name",
                fieldType: 'text',
				cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company_firstName == null"><a href="#/devices/company/{{ row.entity.company_id }}" >{{ row.entity.company_name }}</a></div>' +
                '<div class="ui-grid-cell-contents" data-ng-if="row.entity.company_firstName != null"><i data-ng-show="row.entity.isTest" title="Techniczne" class="fa fa-warning"></i><a href="#/devices/company/{{ row.entity.company_id }}" >{{ row.entity.company_firstName }} {{ row.entity.company_surname }}</a></div>'                
            },
            {
                name: 'Osoba kontaktowa',
                width: '*',
                field: "contact_surname",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" title="{{ row.entity.users }}">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },
            {
                name: 'Administrator',
                width: '*',
                field: 'admin.surname',
                fieldType: 'text',
                visible: false,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.admin_firstName }} {{ row.entity.admin_surname }}</div>'
            },
            {
                name: 'Substancja lub mieszanina',
                width: '*',
                field: "chemicals.name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.chemicalName }}</div>'
            },
            {
                name: 'Urządzenie',
                field: "devices.name",
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Numer seryjny',
                field: "serialNumber",
                fieldType: 'text'
            },
            {
                name: 'Data dodania',
                field: 'devices.created_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Data aktualizacji',
                field: 'devices.updated_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.updated_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Miejsce eksploatacji',
                field: 'exploitation_city',
                fieldType: 'text'
                
            },
            {
                name: 'Kategoria',
                field: 'category_one.name',
                fieldType: 'text'
                
            },
             {
                name: 'Podkategoria',
                field: 'category_two.name',
                fieldType: 'text',
                visible: false
            },
            {
                name: 'Ilość substancji w urządzeniu',
                width: '120',
                enableFiltering: true,
                enableSorting: true,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'quantity',
                //visible: false,
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents">{{ row.entity.quantity }}</div>';
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                	'<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
	                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 1, label: 'od 3 do 30 kg'}, {id: 2, label: 'od 30 do 300 kg'}, {id: 3, label: 'od 300 kg'}]
                }
            },
			{
                name: 'Przedziały ilości ekwiwalentu CO2 FGC',
                width: '120',
                enableFiltering: true,
                enableSorting: true,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'equivalent',
                visible: false,
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="!row.entity.old_limit">{{ row.entity.equivalent }}</div>' +
                    '<div class="ui-grid-cell-contents" ng-if="row.entity.old_limit"></div>';
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                	'<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
	                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 1, label: 'od 5 ton do 50 ton'}, {id: 2, label: 'od 50 ton do 500 ton'}, {id: 3, label: 'od 500 ton'}]
                }
            },
            {
                name: 'Data kontroli szczelności',
                field: "devices.tightness_test_date",
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents" ng-style="row.entity.warning == 1 && { \'background-color\': \'red\', \'color\': \'white\' }">' +
	                '<span ng-if="row.entity.status == 0">' +
	                '<span ng-if="row.entity.testDate != \'false\'">{{ row.entity.tightnessTestDate }}</span>' +
	                '<span ng-if="row.entity.testDate == \'false\'">wymaga naprawy</span>' +
	                '</span>' +
                	'</div>'
            },
            {
                name: "Status",
                width: '120',
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'devices.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Otwarta</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zamknięta</div></div>'
                },

                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 0, label: 'Otwarta'}, {id: 1, label: 'Zamknięta'}]
                }
            },
            {
                name: "Edycja",
                fieldType: 'text',
                width: '100',
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center"><a href="#/devices/{{ row.entity.id }}" class="btn btn-primary btn-xs">Zobacz</a></div></div>'
            }
        ];

        self.getGridOptions = function () {
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {

            if (self.scope.loggedUser.role.name != self.scope.userRoles.su && gridColumns[0].name == 'Podmiot') {
                gridColumns.splice(0, 1);
            }

            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function (type) {
            gridService.query.type = type;

            return gridService.getPage();
        };


        self.getCategories = function (type, device, chemical) {
        	var query = { type: type, device: device };
        	if (typeof chemical !== 'undefined' && chemical) {
                query.chemical = chemical;
            }
            return deviceCategoriesResource.search(query, {action: "find"}).$promise;
        };

        self.getChemicals = function (searchText, type, conditions) {
        	var query = {};
        	query.subId = searchText;
        	if (typeof type !== 'undefined' && type) {
                query.type = type;
            }
        	if (typeof conditions !== 'undefined' && conditions) {
        		angular.forEach(conditions, function(item, name) {
                    query['conditions[' + name + ']'] = item;
                });
            }
            return chemicalsResource.search(query, { action: 'find' }).$promise;
        };

        self.getCertificates = function (search, field, type) {
        	var query = {};

        	if (typeof field === 'undefined' || !field) {
        		field = 'name';
            }
            query['filters[0][field]'] = field;
            query['filters[0][search]'] = search;

            if (typeof type === 'undefined' || !type) {
            	type = 'person';
            }
        	query['filters[1][field]'] = 'type';
            query['filters[1][search]'] = type;

            return techniciansDataResource.get(query).$promise;
        };

        self.checkOpenCart = checkOpenCart;
        self.openCart = openCart;
        self.checkCategoriesTwo = checkCategoriesTwo;

        self.markOperation = markOperation;

        self.getCloseReasons = getCloseReasons;

        self.getCloseReasonById = getCloseReasonById;

		self.deleteDevice = deleteDevice;

		self.devicesCount = devicesCount;

        function checkOpenCart() {
            return devicesResource.get({action: 'openCart'}).$promise;
        }

        function openCart(data) {
            return devicesResource.update(data).$promise;
        }

        function checkCategoriesTwo ($selectedItem) {

            var categoryOneWithoutSeconds = [7, 8, 21, 22];

            return categoryOneWithoutSeconds.indexOf($selectedItem.id) != -1

        }

        function markOperation(cartId, operationInputId) {
            return devicesResource.update({id: cartId, action: 'operations'}, {operationInputId: operationInputId}).$promise;
        }


        function getCloseReasons() {
            return [
                {id: 1, text: 'Całkowity odzysk'},
                {id: 2, text: 'Wymiana czynnika lub środka gaśniczego na inny nie będący substancją kontrolowana lub fluorowanym gazem cieplarnianym'},
                {id: 3, text: 'Operator nie jest już użytkownikiem lub właścicielem urządzenia lub systemu ochrony ppoż.'},
                {id: 4, text: 'Inny'}
            ];
        }

        function getCloseReasonById(id) {
            var reasons = getCloseReasons(),
                reason = null;

            reasons.map(function (item) {
                if (item.id == id) {
                    reason = item.text;
                    return false;
                }
            });

            return reason;
        }

		function deleteDevice(id) {
            return devicesResource.delete({id: id}).$promise;
        }
		
		function deletedInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Usunięto');
                return response.data;
            }		
			
			return false;
		}

		function devicesCount()
		{
            return devicesResource.get({ action: 'count' }).$promise;
        }

        return self;

    }]);