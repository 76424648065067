devices.controller('DevicesPdfController', ['$scope', '$uibModal', 'devicesResource', 'devicesPdfService', 'toaster', '$window',
    function ($scope, $uibModal, devicesResource, devicesPdfService, toaster, $window) {
        var self = this;

        self.modalInstance = null;

        $scope.data = {
            devices: []
        };

        $scope.links = [];

        devicesPdfService.setScope($scope);
        $scope.gridOptions = devicesPdfService.getGridOptions();

        $scope.generatePdf = generatePdf;
        $scope.generatePdfDevices = generatePdfDevices;
        $scope.openModal = openModal;
        $scope.addToList = addToList;
		$scope.addAllToList = addAllToList;
        $scope.cancel = cancel;
        $scope.removeDevice = removeDevice;

        function generatePdf(devices, number)
        {
        	var data = {};
        	data.devices = devices;
        	if (typeof number !== 'undefined' && number) {
        		data.number = number;
        	}

			$scope.saving = true;
            devicesPdfService.getPDF(data).then(function (name) {
				$scope.saving = false;
            	if (name) {
					clearData();
            		$window.location.href = '/devices/download-pdf/' + name;
            	}
            });
        };

        function generatePdfDevices(data)
        {
        	if (data.devices.length == 1) {
        		var device = data.devices.shift();
        		$window.location.href = '/devices/' + device.id + '/pdf';
        	} else {
        		var ids = [];
        		for (var i = 0; i < data.devices.length; i++) {
        			ids.push(data.devices[i].id);
        		}
        		var limit = 100;
        		var params = [];
        		while (ids.length > 0) {
        			params.push(ids.splice(0, limit).join(';'));
        		}
        		if (params.length == 1) {
        			//$window.location.href = '/devices/generate-pdf/?devices=' + params[0];
        			generatePdf(params[0]);
        		} else {
        			$scope.links = params;
        			clearData();
        		}
        		//clearData();
        	}
        }

        function clearData()
        {
            $scope.data = {
                devices: []
            }
        }

        function addToList(device)
        {
            if (Utils.inArray(device, $scope.data.devices)) {
                toaster.pop('warning', 'Karta już dodana');
                return false;
            }

            $scope.data.devices.push(device);

            toaster.pop('info', 'Karta dodana');
        }

        function addAllToList()
        {
			for (var i = 0, len = $scope.gridOptions.data.length; i < len; i++) {
				addToList($scope.gridOptions.data[i]);
			}
        }

        function openModal()
        {
            $scope.gridOptions = devicesPdfService.getGridOptions();

            self.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/devices/views/modal.devices.html',
                size: 'xxl',
                scope: $scope,
                resolve: {
                    items: function () {
                        devicesPdfService.getPage();
                    }
                }
            })
        }

        function cancel()
        {
            self.modalInstance.dismiss('cancel');
        }

        function removeDevice($index)
        {
            $scope.data.devices.splice($index, 1);
        }

        $scope.getDevicesByType = function (type) {
        	devicesPdfService.getPage(type);
        };
    }
]);