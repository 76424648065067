devices.controller('DeviceAccessController', ['$scope', 'devicesResource', '$state','id','Session','toaster',
    function ($scope, devicesResource, $state, id, Session, toaster) {

        $scope.user = {
            email: $scope.loggedUser.email,
            password: ''
        };

        $scope.loading = false;
        $scope.hideForm = true;

        if ($scope.loggedUser.role.name == $scope.userRoles.su || $scope.loggedUser.company.device_password_check != 1) {
            Session.addCart(id);
            return $state.go('devices.list.edit', {id: id});
        } else {
            $scope.hideForm = false;
        }




        $scope.unlock = function () {
            $scope.loading = true;
            devicesResource.checkAccess({id: id, action: 'checkAccess'}, $scope.user, function(response){
                $scope.loading = false;
                if (response.success == true) {
                    Session.addCart(id);
                    return $state.go('devices.list.edit', {id: id});
                } else {
                    toaster.pop('error', response.error);
                    //return $state.go('devices.list');
                }
            });


        };


    }]);

