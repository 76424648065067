
App.directive('locationFilter', ['$timeout', function($timeout) {
        return {
            restrict : "A",
            // require: 'ngModel',
            template: '<div class="well well-sm">' +
            '<input type="text" name="city" data-ng-change="upd()" data-ng-model="data.location" placeholder="Szukaj" class="form-control" >' +
            '</div>' +
            '<div style="display:inline-block" ><div role="button" class="ui-grid-filter-button" data-ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div>',


            link: function(scope, element, attrs, ngModel) {

                scope.data = {location: null};

                // scope.data.location = {
                //     city: null,
                //     street: null
                // };

                scope.colFilter.term = {city: null};
                // console.log(ngModel);

                // scope.$watch('data.location.city', function (newValue) {
                //     console.log("watch", newValue);
                //     scope.colFilter.term = {city: newValue};
                //     scope.$apply();
                // });

                scope.upd = function(){
                    scope.colFilter.listTerm = [];
                    scope.colFilter.term.city = scope.data.location;

                    // console.log("upd",  scope.data.location.city, scope.colFilter.term );
                    // $timeout(function () {
                    //     scope.$apply(function () {
                    //         ngModel.$setViewValue(scope.data.location.city);
                    //         console.log(ngModel);
                    //     });
                    // })
                };


                scope.removeFilter = function (colFilter, $index) {

                    scope.data.location = null;
                    colFilter.term = null;
                };



            },
        };
    }]
);
