/**
 * Created by jgolebiewski on 08.06.15.
 */
App.directive('uniform',['$timeout', function($timeout){
    return {
        restrict:'A',
        require: 'ngModel',
        link: function(scope, element, attr, ngModel) {
            element.uniform({useID: false});
            scope.$watch(function() {return ngModel.$modelValue}, function() {
                $timeout(jQuery.uniform.update, 0);
            } );
        }
    };
}]);