auth.controller('RemindController', ['$scope','$http','Session', function ($scope, $http, Session) {

    $scope.data = {
        email: '',
    };

    $scope.success = false;

    $scope.errors = null;

    $scope.remind = function (data) {
        $scope.errors = null;

        $http.post('/password/email', data).then(function (response) {

            if (response.data.success == true) {
                $scope.success = true;

            } else {
                $scope.errors = response.data.error;
            }

            return false;
        });

    };


}]);