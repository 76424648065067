/**
 * Created by jgolebiewski on 17.12.2015.
 */
common.directive('nipChecker', function () {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {

            var required = attrs.required == undefined ? false : attrs.required;

            ctrl.$parsers.unshift(function (nip) {

                var validate = validateNIP(nip);
                ctrl.$setValidity('nip', validate);

                if (validate == true) {
                    return nip;
                }

                return undefined;

            });


            function validateNIP(nip) {

                if (nip == null && required == true) {
                    return false;
                }

                if ((nip == null || nip == '') && required == false) {
                    return true;
                }

                nip = nip.replace(/\_/g, '');
                nip = nip.replace(/^\s+|\s+$/gm,'');
 
                if (nip.length != 10) {
                    return false;
                }


                for (var i = 0; i < 10; i++) {
                    if (isNaN(nip[i])) {
                        return false;
                    }
                }

                var sum = 6 * nip[0] + 5 * nip[1] + 7 * nip[2] + 2 * nip[3] + 3 * nip[4] + 4 * nip[5] + 5 * nip[6] + 6 * nip[7] + 7 * nip[8];
                sum %= 11;

                if (nip[9] != sum) {
                    return false;
                }
                return true;
            }
        }
    }
});