
parameters.factory('ParametersResource', ['$resource', function($resource) {
    return $resource('/parameters/:action/:key', { action: '@action', key: '@key' }, {
    	update: {
            method: 'PUT'
        },
        query: {
            isArray: false
        },
        search: {
            method: 'GET',
            params: {
                search: '@query',
            }
        },
    })
}]);