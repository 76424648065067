users.controller('UserProfileController', ['$scope', 'usersResource', 'toaster', 'messagesResource', '$state',
    function ($scope, usersResource, toaster, messagesResource, $state) {

        $scope.saving = false;

        $scope.data = $scope.loggedUser;
        $scope.data.password = '';
        $scope.data.passwordVerify = '';


        $scope.save = function () {

            $scope.saving = true;

            var data = angular.copy($scope.data);

            if (data.password == '') {
                delete data.password;
                delete data.passwordVerify;
            }

            usersResource.update({id: $scope.data.id}, data, function (response) {
                $scope.saving = false;
                if (response.success == true) {
                    toaster.pop('success', "Użytkownik został zaktualizowany");
                    $scope.data.history = response.data.history;
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }

    }]);