devices.service('devicesPdfService', ['gridService', 'devicesResource',
    function (gridService, devicesResource) {
        var self = this;

        self.scope = null;
        self.resourceParams = null;

        self.gridColumns = [
            {
                name: 'Id',
                field: 'id',
                fieldType: 'number',
                visible: false
            },
            {
                name: 'Imię i nazwisko',
                width: '*',
                field: 'contact_surname',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.firstName }} {{ row.entity.surname }}</div>'
            },
            {
                name: 'Urządzenie',
                field: 'devices.name',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.name }}</div>'
            },
            {
                name: 'Numer seryjny',
                field: 'serialNumber',
                fieldType: 'text'
            },
            {
                name: 'Data dodania',
                field: 'devices.created_at',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }}</div>'
            },
            {
                name: 'Miasto (eksploatacji)',
                field: 'devices.exploitation_city',
                fieldType: 'text',
                enableFiltering: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.exploitation_city }}</div>'
            },
            {
                name: 'Adres (eksploatacji)',
                field: 'devices.exploitation_street',
                fieldType: 'text',
                enableFiltering: true,
                cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity | formatAddress }}</div>'
            },
            {
                name: 'Status',
                width: '120',
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'devices.status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Otwarta</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zamknięta</div></div>'
                },
                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                	'<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                	'<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 0, label: 'Otwarta'}, {id: 1, label: 'Zamknięta'}]
                }
            },
            {
                name: 'Wybierz',
                fieldType: 'text',
                width: '100',
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center"><button data-ng-click="grid.appScope.addToList(row.entity)" class="btn btn-primary btn-sm btn-addall">Wybierz</button></div></div>'
            }
        ];

        self.setScope = function (scope) {
            self.scope = scope;
        };

        self.getGridOptions = function () {
            gridService.resource = devicesResource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.gridColumns;

            return gridOptions;
        };

        self.getPage = function (type) {
        	if (typeof type !== 'undefined' && type) {
        		gridService.query.type = type;
        	}

            return gridService.getPage();
        };

        self.getPDF = function (data) {
        	return devicesResource.get(data, { action: 'generate-pdf' }).$promise.then(function (response) {
        		if (response.success) {
        			return response.data.name;
        		} else {
        			console.error('Błąd generowania pliku', response);
        			return false;
        		}
        	});
        };

        return self;
    }
]);