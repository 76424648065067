(function () {
  'use strict';

  angular.module('app.stats')
    .controller('StatsController', StatsController);

  StatsController.$inject = [
    '$scope',
    'StatsService',
    '$filter',
    '$window'
  ];

  function StatsController(
    $scope,
    StatsService,
    $filter,
    $window
  ) {
    $scope.statsCriteria = {};
    $scope.range = {
        startDate: null,
        endDate: null
    };

    $scope.dateRangePickerOptions = {
        locale: {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Wybierz",
            "cancelLabel": "Anuluj",
            "fromLabel": "Od",
            "toLabel": "Do",
            "customRangeLabel": "Zdefiniuj"
        }
    };

    $scope.submit = function() {
      $scope.loadingData = true;
      if ($scope.statsForm.$valid) {

        $scope.params = {};
        $scope.params.year = $scope.statsYear;

        if ($scope.range.startDate !== null) {
            $scope.params.startDate = $scope.range.startDate.format('YYYY-MM-DD');
        }
        if ($scope.range.endDate !== null) {
            $scope.params.endDate = $scope.range.endDate.format('YYYY-MM-DD');
        }

        StatsService.getStatsData($scope.params).then( function(res) {
          $scope.loadingData = false;
          $scope.stats = res.data;
          $scope.statsDate = new Date();
        });
      }
    };

  }
})();