/**
 * Created by jgolebiewski on 3.11.2015.
 */
users.factory('registerResource', ['$resource', function ($resource) {
    return $resource('/register/:action/:id', {action: '@action', id: '@id'}, {
        update: {
            method: 'PUT'
        },
        show: {
            params: {
                'action': 'show'
            }
        },
        get: {
            params: {
                'action': 'list'
            }
        }
    });
}]);