users.service('techniciansSubscriptionsService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'SU_TYPES', 'LOGGED_USER', function ($http, uiGridConstants, moment, gridService, SU_TYPES, LOGGED_USER) {
    var self = this;

    self.resource = null;

    self.gridApi = null;

    self.scope = null;

    self.deviceCategories = [];

    self.resourceParams = {};

    var gridColumns = [
        {
            name: 'Imię i nazwisko',
            field: "surname",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents"><span>{{ row.entity.firstName }} {{ row.entity.surname }}</span></div>'
        },
        {
            name: 'Adres e-mail',
            field: "email",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.email }}</div>'
        },
        {
            name: 'Certyfikat',
            field: "certificate",
            fieldType: 'text',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.certificate }}</div>'
        },
        {
            name: 'Data rejestracji',
            field: 'created_at',
            fieldType: 'dateRange',
            enableFiltering: true,
            cellFilter: 'date:"longDate"',
            filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
            cellTemplate: '<div class="ui-grid-cell-contents r">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }} <em>( <small am-time-ago="row.entity.created_at"></small> )</em></div>'
        },
        {
            name: 'Akcje',
            fieldType: 'text',
            width: '200',
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents"><div class="text-center">' +
            	'<a href="#/technicians/edit/{{ row.entity.id }}" class="btn btn-primary btn-xs">Edycja</a>' +
            	'<a href data-ng-click="grid.appScope.showDeleteModal(row.entity)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> <span class="hidden-sm hidden-xs">Usuń</span></a>' +
            '</div></div>'
        }
    ];

    self.getGridOptions = function () {
        gridService.query.type = 1;
        gridService.resource = self.resource;
        gridService.scope = self.scope;
        gridService.resourceParams = self.resourceParams;

        var gridOptions = gridService.getGridOptions();

        gridOptions.columnDefs = self.getGridColumns();
        return gridOptions;
    };

    self.getGridColumns = function () {
        return gridColumns;
    };

    self.getPage = function (){
        return gridService.getPage();
    };

    return self;

}]);