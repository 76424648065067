(function () {
  'use strict';

  var exportModule = angular.module('app.export', [
    'ui.router',
    'roles',
    'suTypes',
    'common',
    // dodane tylko dlatego, że productTypesResource jest w tym module - powinno być raczej w common
    'reports',
    // dodane tylko dlatego, że companyService jest w tym module - pytanie czy można to zrobić jakoś inaczej?
    'users'
  ]);

  exportModule.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', config]);

  function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider
      .state('export', {
        url: '/export',
        abstract: true
      })

      .state('export.list', {
        url: '',
        views: {
          '@': {
            templateUrl: appRoot + 'export/views/export.list.html',
            controller: 'ExportController'
          }
        },
        ncyBreadcrumb: {
          label: 'Raporty'
        },
        data: {
          authorizedRoles: [USER_ROLES.su, USER_ROLES.admin]
        }
      });
  }
})();


