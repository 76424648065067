users.controller('CompanyController', ['$scope', 'companyResource','toaster','provincesService','companyEntity',
    function ($scope, companyResource, toaster, provincesService, companyEntity) {

        var disabled = false;

        if ($scope.loggedUser.role.name == $scope.userRoles.su) {
            disabled = true;
        }

        $scope.disabled = disabled;
        $scope.isNipEditDisabled = true;
        $scope.data = companyEntity.data;
        $scope.files = [];
        $scope.reports = [];
        $scope.provinces = provincesService.getProvincesWithoutNames();
        $scope.save = save;


        getCompanyFiles();
        getReportFiles();


        $scope.loadHistoryEvent = function (){
            $scope.$broadcast("loadHistoryEvent", true);
        };
        
        $scope.loadUserHistoryEvent = function (){
            $scope.$broadcast("loadUserHistoryEvent", true);
        };

        $scope.loadAdminAndUsers = function (){
            $scope.$broadcast("loadAdminAndUsers", true);
        };

        $scope.closeCompanyEvent = function (){
            $scope.$broadcast("closeCompanyEvent", true);
        };


        function save() {
            companyResource.update({id: $scope.data.id}, $scope.data, function(response){
                if (response.success == true) {
                    toaster.pop('success', 'Dane zaktualizowane');
                    $scope.loggedUser.company.device_password_check = $scope.data.device_password_check;
                } else {
                    toaster.pop('error', response.error);
                }
            });
        }


        function getCompanyFiles () {
            companyResource.get({id: $scope.data.id, action: 'files'}, function (response) {
                if (response.success == true) {
                    $scope.files = response.data;
                }
            });
        }


        function getReportFiles() {
            companyResource.get({id: $scope.data.id, action: 'reports'}, function (response) {
                if (response.success) {
                    $scope.reports = response.data;
                }
            });
        }
    }]);