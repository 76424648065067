
App.directive('dateFilter', function() {
        return {
            template: '<input date-range-picker  class="ui-grid-filter-input" type="text" data-ng-model="colFilter.term" data-options="dateRangePickerOptions" placeholder="Wybierz datę" />' +
            '<div style="display:inline-block" ng-show="colFilter.term.startDate !== undefined && colFilter.term.startDate != null"><div role="button" class="ui-grid-filter-button" data-ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
            link: function(scope) {

            	if (!scope.colFilter.term) {
	                scope.colFilter.term = {
	                    startDate: null,
	                    endDate: null
	                };
            	}


                scope.removeFilter = function (colFilter, $index) {

                    scope.colFilter.term = {
                        startDate: null,
                        endDate: null
                    };
                };

                scope.dateRangePickerOptions = {
                    autoUpdateInput: false,
                    opens: "left",
                    startDate: scope.colFilter.term.startDate || moment().subtract(1, 'days'),
                    endDate: scope.colFilter.term.endDate || moment().subtract(1, 'days'),
                    locale: {
                        "format": "DD/MM/YYYY",
                        "separator": " - ",
                        "applyLabel": "Wybierz",
                        "cancelLabel": "Anuluj",
                        "fromLabel": "Od",
                        "toLabel": "Do",
                        "customRangeLabel": "Zdefiniuj"
                    },
                    //ranges: {
                    //    'Dzisiaj': [moment(), moment()],
                    //    'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    //    'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                    //    'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                    //    'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                    //    'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    //}
                };

            },
        };
    }
);
