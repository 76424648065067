/**
 * RegisterController
 */

register.controller('RegisterController', ['$scope', 'provincesResource', 'registerResource', '$state', function ($scope, provincesResource, registerResource, $state) {

    $scope.data = {
        company: {
            type: 0
        },
        user: {},
        documents: []
    };

    $scope.loadingContent = true;
    $scope.$on('$includeContentLoaded', function(event) {
        $scope.loadingContent = false;
    });



    $scope.provinces = [
        {"name": "dolnośląskie"},
        {"name": "kujawsko-pomorskie"},
        {"name": "lubelskie"},
        {"name": "lubuskie"},
        {"name": "łódzkie"},
        {"name": "małopolskie"},
        {"name": "mazowieckie"},
        {"name": "opolskie"},
        {"name": "podkarpackie"},
        {"name": "podlaskie"},
        {"name": "pomorskie"},
        {"name": "śląskie"},
        {"name": "świętokrzyskie"},
        {"name": "warmińsko-mazurskie"},
        {"name": "wielkopolskie"},
        {"name": "zachodniopomorskie"}
    ];
    //provincesResource.query(function (response) {
    //    $scope.provinces = response;
    //});

    $scope.sendingForm = false;
    $scope.errors = null;
    $scope.save = function (data) {
        $scope.errors = null;
        $scope.sendingForm = true;
        $scope.errors = null;
        registerResource.save(data, function (response) {
            $scope.sendingForm = false;
            if (response.success == true) {
                return $state.go("message");
            } else {
                $scope.errors = response.error;
            }
        });

    };

}]);