users.controller('AccountsToDeleteController', ['$scope', '$http', 'toCloseService', 'companyResource', function ($scope, $http, toCloseService, companyResource) {

	$scope.listName = 'AccountsToDeleteList';

    toCloseService.scope = $scope;
    toCloseService.resource = companyResource;
    toCloseService.resourceParams = {action: 'toclose'};
    $scope.gridOptions = toCloseService.getGridOptions();

    toCloseService.getPage();

}]);