
var notices = angular.module('notices', [
    'ui.router', 'roles'
]);

notices.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', function ($stateProvider, $urlRouterProvider, USER_ROLES) {

    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider
        .state('notices', {
            url: '/notices',
            templateUrl: appRoot + 'notices/views/notices.list.html',
            controller: 'NoticesListController',
            ncyBreadcrumb: {
                label: 'Komunikaty'
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('notices.edit', {
            url: '/edit/{id:int}',
            views: {
                '@': {
                    templateUrl: appRoot + 'notices/views/notice.html',
                    controller: 'NoticeController'
                }
            },
            ncyBreadcrumb: {
            	parent: 'notices',
                label: 'Komunikat'
            },
            resolve: {
                id: ['$stateParams', function ($stateParams) {
                    return $stateParams.id;
                }],
                noticeEntity: ['$stateParams', 'NoticesResource', function ($stateParams, NoticesResource) {
                    return NoticesResource.get({ id: $stateParams.id }).$promise;
                }]
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })

        .state('notices.new', {
            url: '/new',
            views: {
                '@': {
                    templateUrl: appRoot + 'notices/views/notice.html',
                    controller: 'NoticeController'
                }
            },
            ncyBreadcrumb: {
                parent: 'notices',
                label: 'Nowy komunikat'
            },
            resolve: {
            	id: function() {
                    return null;
                },
                noticeEntity: function () {
                    return null;
                }
            },
            data: {
                authorizedRoles: [USER_ROLES.su]
            }
        })
}]);