users.service('companyService', ['$http', 'uiGridConstants', 'moment', 'gridService', 'companyResource', 'toaster',
    function ($http, uiGridConstants, moment, gridService, companyResource, toaster) {
        var self = this;

        self.resource = companyResource;

        self.gridApi = null;

        self.scope = null;

        self.resourceParams = {};

        var dateRangePickerOptions = {
            "locale": {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Wybierz",
                "cancelLabel": "Anuluj",
                "fromLabel": "Od",
                "toLabel": "Do",
                "customRangeLabel": "Zdefiniuj"
            },
            ranges: {
                'Dzisiaj': [moment(), moment()],
                'Wczoraj': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Z ostatnich 7 dni': [moment().subtract(6, 'days'), moment()],
                'Z ostatnich 30 dni': [moment().subtract(29, 'days'), moment()],
                'Z tego miesiąca': [moment().startOf('month'), moment().endOf('month')],
                'Z poprzedniego miesiąca': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        };

        var gridColumns = [
            {
                name: 'Imię',
                field: "firstName",
                fieldType: 'text',
            },
            {
                name: 'Nazwisko',
                field: "surname",
                fieldType: 'text',
            },
            {
                name: 'E-mail',
                field: "email",
                fieldType: 'text',
            },
            {
                name: 'Telefon',
                field: "phone",
                fieldType: 'text',
            },

            {
                name: 'Data rejestracji',
                field: 'registrationDate',
                fieldType: 'dateRange',
                enableFiltering: true,
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters"><div date-filter></div></div>',
                cellTemplate: '<div class="ui-grid-cell-contents text-center">{{ row.entity.created_at | amDateFormat:"DD/MM/YYYY HH:mm" }} <em>( <small am-time-ago="row.entity.created_at"></small> )</em></div>'
            },
            {
                name: 'Typ konta',
                field: 'roleId',
                fieldType: 'text',
                cellTemplate: '<div class="ui-grid-cell-contents" data-ng-if="row.entity.role.name == \'ADMIN\'">Administrator</div><div class="ui-grid-cell-contents" data-ng-if="row.entity.role.name == \'USER\'">Użytkownik</div>',
                filterHeaderTemplate: '<div class="ui-grid-filter-container" data-ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" data-ng-model="colFilter.term" data-ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" data-ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" data-ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: {}
                }
            },
            {
                name: "Status",
                width: '120',
                enableFiltering: true,
                enableSorting: false,
                enableColumnMenu: false,
                fieldType: 'text',
                field: 'status',
                cellTemplate: function () {
                    return '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 0"><div class="label label-success">Aktywny</div></div>' +
                        '<div class="ui-grid-cell-contents" ng-if="row.entity.status == 1"><div class="label label-danger">Zablokowany</div></div>' +
						'<div class="ui-grid-cell-contents" ng-if="row.entity.status == 3"><div class="label label-default">Oczekuje</div></div>' +
						'<div class="ui-grid-cell-contents" ng-if="row.entity.status == 5"><div class="label label-default">Oczekuje</div></div>'
                },

                filterHeaderTemplate: '<div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">' +
                '<select  class="form-control" ng-model="colFilter.term" ng-options="option.id as option.label for option in colFilter.options"></select>' +
                '<div role="button" ng-show="colFilter.term !== undefined && colFilter.term != null" class="ui-grid-filter-button od-remove-filter" ng-click="removeFilter(colFilter, $index)"><i class="ui-grid-icon-cancel"></i></div></div>',
                filter: {
                    options: [{id: 0, label: 'Aktywny'}, {id: 1, label: 'Zablokowany'}]
                }
            },
            {
                name: "Edycja",
                fieldType: 'text',
                //width: '200',
                enableFiltering: false,
                enableSorting: false,
                enableColumnMenu: false,
                cellTemplate: '<div class="ui-grid-cell-contents">' +
                '<div class="text-center">' +
                '<a href="#/users/{{ row.entity.id }}" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i> <span class="hidden-sm hidden-xs">Edytuj</span></a>' +
                '</div></div>'
            }
        ];


        self.getGridOptions = function () {
            gridService.query.type = 0;
            gridService.resource = self.resource;
            gridService.scope = self.scope;
            gridService.resourceParams = self.resourceParams;

            var gridOptions = gridService.getGridOptions();

            gridOptions.columnDefs = self.getGridColumns();
            return gridOptions;
        };

        self.getGridColumns = function () {
            return gridColumns;

        };

        self.getDateRangePickerOptions = function () {
            return dateRangePickerOptions;
        };

        self.getPage = function () {
            return gridService.getPage();
        };


        self.getAdmin = getAdmin;
        self.getAdminCurr = getAdminCurr;

        self.getNonAdmins = getNonAdmins;

        self.checkOpenCart = checkOpenCart;

        self.sendCloseRequest = sendCloseRequest;

        self.getCompanies = getCompanies;
        self.getCompany = getCompany;

        self.assign = assign;


        function getAdmin(companyId) {
            return companyResource.get({id: companyId, action: 'getAdmin'}).$promise.then(manageResponse);
        }

        function getAdminCurr(companyId) {
            return companyResource.get({id: companyId, action: 'getAdminCurr'}).$promise.then(manageResponse);
        }

        function getNonAdmins(companyId) {
            return companyResource.get({id: companyId, action: 'users', subId: 3}).$promise.then(manageResponse);
        }

        function manageResponse(response) {
            if (response.success == true) {
                return response.data;
            } else {
                console.warn(response);
            }
        }


        function checkOpenCart() {
            return companyResource.get({action: 'openCart'}).$promise;
        }


        function sendCloseRequest(companyId, data) {
            return companyResource.save({id: companyId, action: 'closeRequest'}, data).$promise.then(showResponseInfo);
        }

        function showResponseInfo(response) {
            if (response.success == true) {
                toaster.pop('success', 'Dane uaktualnione');
                return response.data;
            } else {
                toaster.pop('error', 'Bład aktualizacji');
                console.warn(response);
            }
        }


        function getCompanies(searchObj) {
            return companyResource.search(searchObj).$promise;
        }

        function getCompany(companyId)
        {
            return companyResource.get({ id: companyId }).$promise;
        }

        function assign(companyId, caseToAssign)
        {
            if (caseToAssign == null || caseToAssign == undefined) {
                return false;
            }

            return companyResource.assign({ id: companyId }, { caseToAssign: caseToAssign }).$promise.then(showAssignInfo);
        }

        function showAssignInfo(response)
        {
            if (response.success) {
                toaster.pop('success', 'Zostałeś przypisany');
                return response.data;
            } else {
                toaster.pop('error', response.error);
                console.warn(response);
            }
        }

        self.getFiles = function (id) {
            return companyResource.get({id: id, action: 'files'}).$promise;
        };

        return self;

    }]);