devices.directive('companyDevicesXls', function () {
    return {
        templateUrl: appRoot + '/devices/views/directives/company.devices.xls.directive.html',
        restrict: 'E',
        replace: true,
        controller: ['$scope', 'devicesService', 'companyDevicesService', 'activitiesService', 'chemicalAddedTypesService', '$window', function ($scope, devicesService, companyDevicesService, activitiesService, chemicalAddedTypesService, $window) {
        	$scope.dateRangePickerOptions = {
    			autoUpdateInput: false,
    			opens: 'left',
    			startDate: moment().subtract(1, 'days'),
    			endDate: moment().subtract(1, 'days'),
    			locale: {
    				'format': 'DD/MM/YYYY',
    				'separator': ' - ',
    				'applyLabel': 'Wybierz',
    				'cancelLabel': 'Anuluj',
    				'fromLabel': 'Od',
    				'toLabel': 'Do',
    				'customRangeLabel': 'Zdefiniuj'
    			},
	        };

        	$scope.types = [
            	{ id: 1, label: 'Karty urządzeń' },
            	{ id: 2, label: 'Karty Systemów Ochrony Przeciwpożarowej' },
            ];

        	$scope.filterUser = {
    			name: 'Osoba wypełniająca kartę',
    			field: 'user.surname',
    			fieldType: 'text'
    		}

        	$scope.filterType = {
                name: 'Rodzaj kart',
                field: 'type',
                fieldType: '',
                value: 1
            };

        	$scope.operationsFilters = [
        		{
        			name: 'Data dokonania wpisu',
        			field: 'operations_input.createDate',
        			fieldType: 'dateRange'
        		},
        		{
        			name: 'Osoba dokonująca wpisu',
        			field: 'operations_input.surname',
        			fieldType: 'text'
        		},
        		{
        			name: 'Data rozpoczęcia wykonywania czynności',
        			field: 'operations.startDate',
        			fieldType: 'dateRange'
        		},
        		{
        			name: 'Data zakończenia wykonywania czynności',
        			field: 'operations.endDate',
        			fieldType: 'dateRange'
        		},
        		{
        			name: 'Rodzaj wykonywanej czynności',
        			field: 'operations.type',
        			fieldType: 'ui-select',
        			byName: true,
        			options: activitiesService.getActivities()
        		},
        		{
        			name: 'Ilość substancji dodanej',
        			field: 'operations.added',
        			fieldType: 'text'
        		},
        		{
        			name: 'Rodzaj dodanej substancji kontrolowanej lub FGC',
        			field: 'operations.added_type',
        			fieldType: 'ui-select',
        			options: chemicalAddedTypesService.getList()
        		},
        		{
        			name: 'Ilość substancji odzyskanej',
        			field: 'operations.recovered',
        			fieldType: 'text'
        		},
        		{
        			name: 'Nazwa podmiotu wykonującego czynność',
        			field: 'operations.contractorName',
        			fieldType: 'text'
        		},
        		{
        			name: 'Adres podmiotu wykonującego czynność',
        			field: 'operations.contractorAddress',
        			fieldType: 'text'
        		},
        		{
        			name: 'Numer certyfikatu podmiotu wykonującego czynność',
        			field: 'operations.contractorCertificate',
        			fieldType: 'text'
        		},
        		{
        			name: 'Osoba wykonująca czynność',
        			field: 'technicians.surname',
        			fieldType: 'text'
        		},
				{
        			name: 'Numer certyfikatu osoby wykonującej czynność',
        			field: 'technicians.certificate',
        			fieldType: 'text'
        		}
        	];

        	$scope.showForm = false;
        	$scope.loading = false;
        	$scope.data = {};
        	$scope.filters = [];
        	$scope.category_one = 'category_one';
        	$scope.category_two = 'category_two';
        	$scope.deviceCategories = [];
            $scope.getDeviceCategoriesOne = getDeviceCategoriesOne;
            $scope.checkCategoriesTwo = checkCategoriesTwo;

            $scope.filters.push($scope.filterUser);

            devicesService.scope = $scope;
        	var filters = angular.copy(devicesService.getGridColumns());

        	$.each(filters, function (index, filter) {
        		if (filter.name != 'Podmiot' && filter.name != 'Edycja') {
        			if (filter.field.indexOf('category') != -1) {
        				filter.field = filter.field.replace('name', 'id');
        				if (filter.field.indexOf('category_one') != -1) {
        					$scope.category_one = filter.field;
        					$scope.filters.push($scope.filterType);
        					filter.fieldType = 'category';
        				} else {
        					$scope.category_two = filter.field;
        					return true;
        				}
        			}
        			if (filter.field == 'exploitation_city') {
        				filter.field = 'exploitation_address';
        			}
                    $scope.filters.push(filter);
                }
        	});

        	$.each($scope.operationsFilters, function (index, filter) {
        		$scope.filters.push(filter);
        	});

        	$scope.getDeviceCategoriesOne({ type: 'GROUP' });

        	$scope.closeForm = function () {
        		$scope.showForm = false;
        	};

	        $scope.getXLS = function () {
	        	var filename = 'raport.xlsx';
	        	$scope.loading = true;
	            companyDevicesService.getXLS($scope.company.id, $scope.data).then(function (hash) {
	            	if (hash) {
	            		$scope.loading = false;
	                    $scope.showForm = false;
	            		$window.location.href = '/devices/' + hash + '/' + filename;
	            	}
	            });
	        };

	        $scope.setType = function() {
	    		$scope.getDeviceCategoriesOne({ type: 'GROUP' });

	    		$scope.data[$scope.category_one] = null;
	            $scope.data[$scope.category_two] = null;
	        };

	        function getDeviceCategoriesOne(selectedItem) {
	            if (selectedItem == undefined) {
	                $scope.deviceCategories = [];
	                $scope.data[$scope.category_one] = null;
	                $scope.data[$scope.category_two] = null;
	                return;
	            }

	            $scope.catsLoading = true;

	            devicesService.getCategories(selectedItem.type, $scope.filterType.value).then(function (response) {
	                $scope.catsLoading = false;
	                if (response.success == true) {
	                    $scope.deviceCategories = response.data;
	                }
	            });
	        }

	        function checkCategoriesTwo($selectedItem) {
	            if (devicesService.checkCategoriesTwo($selectedItem)) {
	                $scope.data[$scope.category_two] = null;
	                $scope.unSelectable = true;
	            } else {
	                $scope.unSelectable = false;
	            }
	        }

	        $scope.clearCategory = function ($event, $select) {
	            $event.stopPropagation();
	            $select.selected = null;

	            $scope.data[$scope.category_one] = null;
	            $scope.data[$scope.category_two] = null;
	        };
        }],
        link: function ($scope, element, attr) {
        }
    };
});