register.controller('TechnicianSubscribeController', ['$scope', 'provincesResource', 'techniciansSubscriptionsResource', '$state', function ($scope, provincesResource, techniciansSubscriptionsResource, $state) {

    $scope.data = {};
    $scope.data.certificate = null;
    $scope.data.certificateCountry = { id: 'PL', name: 'Polska' };

    $scope.loadingContent = true;
    $scope.$on('$includeContentLoaded', function(event) {
        $scope.loadingContent = false;
    });

    $scope.sendingForm = false;
    $scope.errors = null;
    $scope.save = function (data) {
        console.log($scope.data);
        $scope.errors = null;
        $scope.sendingForm = true;
        $scope.errors = null;
        techniciansSubscriptionsResource.save(data, function (response) {
            $scope.sendingForm = false;
            if (response.success == true) {
                return $state.go("technician_subscribe_message");
            } else {
                $scope.errors = response.error;
            }
        });

    };

}]);