(function () {
  'use strict';

  var exportModule = angular.module('app.stats', [
    'ui.router',
    'roles',
    'common'
  ]);

  exportModule.config(['$stateProvider', '$urlRouterProvider', 'USER_ROLES', config]);

  function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $urlRouterProvider.otherwise('/dashboard');

    $stateProvider
      .state('stats', {
        url: '/stats',
        views: {
          '@': {
            templateUrl: appRoot + 'stats/views/stats.html',
            controller: 'StatsController'
          }
        },
        ncyBreadcrumb: {
          label: 'Statystyki'
        },
        data: {
          authorizedRoles: [USER_ROLES.su]
        }
      });
  }
})();


