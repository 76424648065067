devices.controller('OperationsController', ['$scope', 'devicesResource', 'usersResource', 'toaster','$filter','moment','provincesService','operationGridService','$uibModal','activitiesService','devicesService', 'chemicalAddedTypesService',
    function ($scope, devicesResource, usersResource, toaster, $filter, moment, provincesService, operationGridService, $uibModal, activitiesService, devicesService, chemicalAddedTypesService) {

        var self = this;

        //$scope.dateRangePickerOptions = {
        //    singleDatePicker: true,
        //    showDropdowns: true,
        //    "locale": {
        //        "format": "DD/MM/YYYY",
        //        "separator": " - ",
        //        "applyLabel": "Wybierz",
        //        "cancelLabel": "Anuluj",
        //        "fromLabel": "Od",
        //        "toLabel": "Do",
        //        "customRangeLabel": "Zdefiniuj"
        //    }
        //};

        $scope.dateRangePickerOptions = {
            'on-open-focus': true,
            'show-button-bar': false
        };

        $scope.maxDate = new Date();

        $scope.showFormDev = {
            s: false,
            c: false,
            o: false
        };

        if ($scope.$parent.isAuthorized != true) {
            return;
        }

        self.editObj = null;
        $scope.idToDelete = null;
        self.itemToMark = null;
        $scope.dateFormat = 'dd/MM/yyyy';

        $scope.chemicalAddedTypes = chemicalAddedTypesService.getList();

        $scope.paginationId = "paginationIdOperation";

        $scope.isAuthorized = true;

        $scope.operations = [];

        $scope.activities = activitiesService.getActivities();

        $scope.provinces = provincesService.getProvinces();

        $scope.quantity = $scope.$parent.data.quantity;
        $scope.category_id = $scope.$parent.data.category_one.id;

        $scope.showIncorrectButton = true;

        $scope.$watch('data.quantity', function (n, o) {
        	if (!n && $scope.$parent.mode != 'edit') {
        		$scope.$parent.data.quantity = o;
        		n = o;
        	}
            $scope.quantity = n;
        });

        $scope.$watch('newOperation.type', function (newValue, oldValue) {
        	if (!$scope.checkTypeCustom(newValue, 'Konserwacja lub serwisowanie') && (!oldValue || $scope.checkTypeCustom(oldValue, 'Konserwacja lub serwisowanie'))) {
        		$scope.newOperation.added = 0;
        	}
			if (!$scope.checkTypeCustom(newValue, 'Odzysk') && (!oldValue || $scope.checkTypeCustom(oldValue, 'Odzysk'))) {
        		$scope.newOperation.recovered = 0;
        	}
        });


        var entryTpl = {
            createDate: new Date(),
            creator: {},
            operations: []
        };

        $scope.entry = angular.copy(entryTpl);




        var tpl =  {
            startDate: new Date(),
            endDate: new Date(),
            leakage_test_result: 0,
            technicians: [{
                firstname: null,
                surname: null,
                certificate: null,
                certificateCountry: {id: 'PL', name: 'Polska'}
            }],
            contractorReq: 1,
            contractor: { abroad: 0 },
            reg_address: { abroad: 0 },
            added_type: $scope.chemicalAddedTypes[0],
        };


        $scope.newOperation = angular.copy(tpl);

        $scope.markAsIncorrect = markAsIncorrect;
        $scope.showMarkModal = showMarkModal;
        $scope.hide = hide;


        $scope.initForm = function(form) {
            $scope.operForm = form;
        };

        $scope.pageSizes = operationGridService.pageSizes;

        $scope.changePageSize = function (value) {
            $scope.pageSize = value;
            self.getOperations();
        };


        $scope.status = {
            start: false,
            end: false
        };

        $scope.openDatepicker = function(elem) {
            $scope.status[elem] = true;
        };




        $scope.pageSize = operationGridService.defaultPageSize;
        $scope.currentPage = 1;
        $scope.total = 0;

        $scope.loadingOperations = false;

        self.getOperations = function () {
            $scope.loadingOperations = true;
            var params = {
                limit: $scope.pageSize,
                page: $scope.currentPage
            };

            devicesResource.get( params, {id: $scope.$parent.data.id, action: 'operations'}, function (response) {
                $scope.loadingOperations = false;
                if (response.success == true) {
                    $scope.operations = response.data;
                    $scope.total = response.total;

                    $scope.index = [];
                    $.each($scope.operations.operations, function (index, operation) {
            			$scope.operations.operations[index].index = $scope.getIndex();

            			if (operation.type.indexOf('Likwidacja urządzenia') !== -1 && operation.liquidation_causes) {
            				operation.type = operation.type.replace('Likwidacja urządzenia', 'Likwidacja urządzenia (' + operation.liquidation_causes + ')');
            			}
                	});
                }
            });
        };


        $scope.$on('loadOper', function(){
            self.getOperations();
        });


        $scope.pageChanged = function (newPage) {
            $scope.currentPage = newPage;
            self.getOperations();
        };


        $scope.addTechnician = function () {
            $scope.newOperation.technicians.push({
                firstName: null,
                surname: null,
                certificate: null,
                certificateCountry: {id: 'PL', name: 'Polska'}
            });

            $scope.loadCertificates($scope.newOperation.technicians.length - 1);

            if ($scope.operations.operations.length) {
            	var operation = $scope.operations.operations.slice(-1)[0];
            	var index = $scope.newOperation.technicians.length - 1;

            	if (operation.technicians.length && operation.technicians[index]) {
            		if (operation.technicians[index].certificateCountryId) {
            			var certificateCountry = {
            				id: operation.technicians[index].certificateCountryId,
            			};
            		} else {
            			var certificateCountry = { id: 'PL', name: 'Polska' };
            		}

		            $scope.newOperation.technicians[index] = {
	            		firstname: operation.technicians[index].firstname,
	            		surname: operation.technicians[index].surname,
	            		certificate: operation.technicians[index].certificate,
	            		certificateCountry: certificateCountry,
	            	};
            	}
            }
        };

        $scope.removeTechnician = function ($index) {
            $scope.newOperation.technicians.splice($index, 1);
        };


        $scope.confirm = function () {
            $scope.showFormDev = {
                s: true,
                c: true
            };
        };

        $scope.showFormOper = function () {
            $scope.showFormDev.o = true;

            $scope.activities = activitiesService.getActivities();

            if ($scope.operations.operations.length) {
            	var operation = $scope.operations.operations.slice(-1)[0];

	            var contractorAddress = operation.contractorAddress;
	            var province = '';
	            var country = '';
	            var abroad = 0;
	            if (contractorAddress.indexOf(' woj. ') !== -1) {
		            contractorAddress = contractorAddress.split(' woj. ');
		            province = contractorAddress[1];
		            abroad = 0;
	            }
	            if (contractorAddress.indexOf(' kraj ') !== -1) {
	            	contractorAddress = contractorAddress.split(' kraj ');
	            	country = contractorAddress[1];
	            	abroad = 1;
	            }
	            if (contractorAddress[0].indexOf(', ') !== -1) {
	            	contractorAddress = contractorAddress[0].split(', ');
	            } else {
	            	contractorAddress = contractorAddress[0].split('/');
	            }
	            contractorAddress[0] = contractorAddress[0].split(' ');
	            var street = contractorAddress[0].slice(0, -1).join(' ');
	            var number = contractorAddress[0].slice(-1)[0];
	            number = number.split('/');
	            var homeNumber = number[0];
	            var flatNumber = '';
	            if (number.length > 1) {
	            	flatNumber = number[1];
	            }
	            contractorAddress = contractorAddress[1];
	            if (abroad == 0) {
		            contractorAddress = contractorAddress.split(' ');
		            var postcode = contractorAddress[0];
		            var city = contractorAddress.slice(1).join(' ');
	            } else {
	            	var postcode = '';
		            var city = contractorAddress;
	            }

	            $scope.newOperation.contractor = {
	            	name: operation.contractorName,
	            	street: street,
	            	homeNumber: homeNumber,
	            	flatNumber: flatNumber,
	            	postcode: postcode,
	            	city: city,
	            	province: { name: province },
	            	country: country,
	            	abroad: abroad,
	            	certificate: operation.contractorCertificate,
	            };

	            if (operation.technicians.length) {
	            	if (operation.technicians[0].certificateCountryId) {
            			var certificateCountry = {
            				id: operation.technicians[0].certificateCountryId,
            			};
            		} else {
            			var certificateCountry = { id: 'PL', name: 'Polska' };
            		}

		            $scope.newOperation.technicians[0] = {
	            		firstname: operation.technicians[0].firstname,
	            		surname: operation.technicians[0].surname,
	            		certificate: operation.technicians[0].certificate,
	            		certificateCountry: certificateCountry,
	            	};
	            }
            }
        };

        $scope.addNewOperation = function () {

            if (self.editObj == null) {
				//ustawiam godzinę, ze względu, że może być GMT+1, co później powoduje odjęcie godziny i potencjalnie przestawienie daty
				$scope.newOperation.endDate.setHours(12);
				$scope.newOperation.startDate.setHours(12);
                $scope.entry.operations.push($scope.newOperation);
            } else {
            	$scope.entry.operations[self.editObj.index].endDate.setHours(12);
            	$scope.entry.operations[self.editObj.index].startDate.setHours(12);
                self.editObj == null;
            }

            $scope.newOperation = angular.copy(tpl);

            self.editObj = null;
            $scope.showFormDev.o = false;
            $scope.operForm.$setSubmitted(true);
            $scope.operForm.$setUntouched(true);

            $scope.loadCertificates(0);
            $scope.loadCompanyCertificate();
        };


        $scope.saving = false;
        $scope.addOperation = function () {
            $scope.saving = true;
            //$scope.entry.createDate = $filter('date')($scope.entry.createDate, 'yyyy-MM-dd'  );
            //console.log($scope.entry);
            //$scope.entry.newOperation.type.push($scope.entry.newOperation.differentType);
            devicesResource.save({id: $scope.$parent.data.id, action: 'operations'}, $scope.entry, function (response) {
                if (response.success == true) {

                    toaster.pop('success', "Dane zapisane");
                    $scope.showFormDev = {
                        s: false,
                        c: false
                    };

                    $scope.entry = angular.copy(entryTpl);

                    $scope.newOperation = angular.copy(tpl);
                    $scope.operForm.$setSubmitted(true);
                    $scope.operForm.$setUntouched(true);

                    $scope.loadCertificates(0);
                    $scope.loadCompanyCertificate();

                    self.getOperations();
                    $scope.$parent.getDevOperations();
                } else {
                    toaster.pop('error', response.error);
                }
                $scope.saving = false;
            });
        };


        $scope.delete = function () {
            $scope.entry.operations.splice($scope.idToDelete, 1);
            $scope.cancel();
        };

        $scope.editOperation = function (index) {
            $scope.newOperation = $scope.entry.operations[index];
            self.editObj = {index: index, obj: angular.copy($scope.newOperation) };
            $scope.showFormDev.o = true;
            $scope.activities = activitiesService.getActivities();
        };

        $scope.cancelNewOperation = function () {

            if (self.editObj != null) {
                $scope.entry.operations[self.editObj.index] = self.editObj.obj;
                self.editObj = null;
            }

            $scope.showFormDev.o = false;
            $scope.newOperation = angular.copy(tpl);
            $scope.operForm.$setPristine(true);
            $scope.operForm.$setSubmitted(true);
            $scope.operForm.$setUntouched(true);

            $scope.loadCertificates(0);
            $scope.loadCompanyCertificate();
        };

        $scope.cancelNewEntry = function () {
            $scope.showFormDev.s = false;
            $scope.entry = angular.copy(entryTpl);
            $scope.newOperation = angular.copy(tpl);

            $scope.loadCertificates(0);
            $scope.loadCompanyCertificate();
        };


        $scope.open = function (id) {

            $scope.idToDelete = id;
            $scope.modalInstance = $uibModal.open({
                templateUrl: appRoot + '/common/views/modals/delete.modal.html',
                size: 'small',
                animation: true,
                scope: $scope,
                resolve: {
                    items: function () {
                        return $scope.items;
                    }
                }
            });
        };

        $scope.cancel = function () {
            $scope.modalInstance.close();
            $scope.idToDelete = null;
        };

        $scope.checkActivities = function ($item, $model) {
        	$scope.activities = activitiesService.getActivities();

        	if ($scope.newOperation.type && $scope.newOperation.type.length) {
				var type = $scope.newOperation.type;
				if (typeof type === 'string') {
					type = type.split(',');
				}

        		$.each($scope.activities, function (index, activiti) {
	        		if (
	        			$.inArray(activiti.name, type) >= 0 ||
	        			$.inArray('Kontrola szczelności', type) >= 0 ||
	        			activiti.name == 'Kontrola szczelności'
		        	) {
		        		delete $scope.activities[index];
		        	}
        		});
        	}
        };

        
        function markAsIncorrect() {
            devicesService.markOperation($scope.$parent.data.id, self.itemToMark).then(function (response) {
                if (response.success == true) {
                    self.getOperations();
                    $scope.$parent.getDevOperations();
                    hide();
                }
            });
        }


        function showMarkModal(itemToDelete) {
            self.itemToMark = itemToDelete;
            self.modalIncorrectInstance = $uibModal.open({
                templateUrl: appRoot + '/devices/views/delete.modal.html',
                // size: 'od-lg',
                animation: true,
                backdrop: 'static',
                keyboard: false,
                scope: $scope
            });
        }

        function hide() {
            self.itemToMark = null;
            self.modalIncorrectInstance.dismiss('cancel');
        }

        $scope.getIndex = function () {
        	var index = $scope.index && $scope.index.length ? $scope.index.length : 0;
        	if (index > 0) {
	        	$scope.index[index] = $scope.index[index - 1];
	        	if ($scope.operations.operations[index].inputId != $scope.operations.operations[index - 1].inputId) {
	        		$scope.index[index]++;
	        	}
        	} else {
        		$scope.index = [1];
        	}

        	return $scope.index[index];
        };

        $scope.checkType = function (type) {
        	var isType = false;

			if ($scope.newOperation.type && $scope.newOperation.type.length) {
				var types = $scope.newOperation.type;
				if (typeof types === 'string') {
					types = types.split(',');
				}

		    	if ($.inArray(type, types) >= 0) {
		    		isType = true;
		    	}
			}

			return isType;
        };

        $scope.checkTypes = function (types, exTypes) {
        	var isType = false;

			if ($scope.newOperation.type && $scope.newOperation.type.length) {
				var selectedTypes = $scope.newOperation.type;
				if (typeof selectedTypes === 'string') {
					selectedTypes = selectedTypes.split(',');
				}

		    	for (var type in selectedTypes) {
		    		type = selectedTypes[type];
			    	if ($.inArray(type, types) >= 0) {
			    		isType = true;
			    	} else if (typeof exTypes === 'undefined' || $.inArray(type, exTypes) === -1) {
			    		return false;
			    	}
		    	}
			}

			return isType;
        };

        $scope.isCertificateRequired = function () {
        	var categories = [7, 21, 22];

        	var categories2 = [1, 2, 3, 4, 5, 6, 8];
        	var types = ['Kontrola szczelności', 'Odzysk'];
        	var types2 = ['Instalowanie systemu wykrywania wycieku', 'Kontrola systemu wykrywania wycieków'];

        	var category = categories2.indexOf($scope.$parent.data.category_one.id) != -1;
        	var type = $scope.newOperation.type == 'Kontrola szczelności' || $scope.newOperation.type == 'Odzysk';
        	var type2 = $scope.newOperation.type == 'Instalowanie systemu wykrywania wycieku' || $scope.newOperation.type == 'Kontrola systemu wykrywania wycieków';
        	category = category || $scope.$parent.data.type == 2;
        	type = type || $scope.checkTypes(types, types2);
        	type2 = type2 || $scope.checkTypes(types2, types);
        	var req = !((category && type) || type2);

        	return categories.indexOf($scope.$parent.data.category_one.id) == -1 && req;
        };

        $scope.checkTypeCustom = function (types, type) {
        	var isType = false;

			if (types && types.length) {
				if (typeof types === 'string') {
					types = types.split(',');
				}

		    	if ($.inArray(type, types) >= 0) {
		    		isType = true;
		    	}
			}

			return isType;
        };

        $scope.certificates = [];
        $scope.loadedCertificates = [];

        $scope.searchCertificates = function (index, field, search) {
        	if (!$scope.newOperation.technicians[index]) {
        		return false;
        	}
    		if ($scope.newOperation.technicians[index].certificateCountry.id != 'PL') {
    			return false;
    		}

    		if (field == 'surname' && $scope.newOperation.technicians[index].firstname.length > 0) {
    			search = $scope.newOperation.technicians[index].firstname + ' ' + search;
    		} else if (field == 'firstname' && $scope.newOperation.technicians[index].surname.length > 0) {
    			search = search + ' ' + $scope.newOperation.technicians[index].surname;
    		}

            if (search.length == 0) {
            	if ($scope.certificates[index].length == 0) {
            		if ($scope.newOperation.technicians[index].firstname.length > 0) {
            			search = $scope.newOperation.technicians[index].firstname;
            		}
        			if ($scope.newOperation.technicians[index].surname.length > 0) {
        				if (search.length > 0) {
        					search = search + ' ';
        				}
            			search = search + $scope.newOperation.technicians[index].surname;
            		}
        			if (search.length == 0) {
        				return false;
        			}
            	} else {
            		return false;
            	}
            }

            devicesService.getCertificates(search).then(function (response) {
                if (response.success == true) {
                    $scope.certificates[index] = response.data;

                    $.each($scope.certificates[index], function (index, technician) {
            			technician.certificate = technician.certificate.replace('FGAZ-O/', '');
                	});

                    if ($scope.certificates[index].length == 1) {
                		$scope.newOperation.technicians[index].certificate = $scope.certificates[index][0].certificate;

                		if (!$scope.loadedCertificates[index]) {
	                		$scope.newOperation.technicians[index].firstname = $scope.certificates[index][0].firstname.charAt(0).toUpperCase() + $scope.certificates[index][0].firstname.slice(1).toLowerCase();
	                		$scope.newOperation.technicians[index].surname = $scope.certificates[index][0].surname.charAt(0).toUpperCase() + $scope.certificates[index][0].surname.slice(1).toLowerCase();

	                		$scope.loadedCertificates[index] = true;
                		}
                	} else if ($scope.certificates[index].length > 1) {
                		$scope.newOperation.technicians[index].certificate = null;
                		$scope.loadedCertificates[index] = false;
                	}
                }
            });
        };

        $scope.loadCertificates = function (index) {
        	$scope.$watch(function () { return $scope.newOperation.technicians[index].firstname; }, function (newValue, oldValue) {
                if (newValue && newValue.length >= 3 && newValue != oldValue) {
        			$scope.searchCertificates(index, 'firstname', newValue);
                }
            });
            $scope.$watch(function () { return $scope.newOperation.technicians[index].surname; }, function (newValue, oldValue) {
                if (newValue && newValue.length >= 3 && newValue != oldValue) {
        			$scope.searchCertificates(index, 'surname', newValue);
                }
            });
        };

        $scope.loadCertificates(0);

        $scope.companyCertificatePattern = /^([0-9]{2})\/([0-9]{4})\/([0-9]{2})$/;
        $scope.companyCertificate = null;

        $scope.isCompanyCertificateSuspended = function () {
        	return (
        		$scope.newOperation.contractor.abroad != 1 &&
        		$scope.newOperation.contractor.certificate &&
        		$scope.companyCertificate &&
        		$scope.companyCertificate.status == 0
        	);
        };

        $scope.searchCompanyCertificate = function (search) {
    		if ($scope.newOperation.contractor.abroad == 1) {
    			$scope.companyCertificate = null;
    			return false;
    		}

            if (search.length == 0) {
            	if (!$scope.companyCertificate) {
            		if ($scope.newOperation.contractor.certificate.length == 10) {
            			search = $scope.newOperation.contractor.certificate;
            		}
        			if (search.length == 0) {
        				return false;
        			}
            	} else {
            		return false;
            	}
            }

            if ($scope.companyCertificate && search.length == 10) {
            	$scope.companyCertificate = null;
            }

            devicesService.getCertificates(search, 'certificate', 'company').then(function (response) {
                if (response.success == true && response.data.length > 0) {
                    $scope.companyCertificate = response.data[0];

                    $scope.companyCertificate.certificate = $scope.companyCertificate.certificate.replace('FGAZ-P/', '');
                }
            });
        };

        $scope.loadCompanyCertificate = function () {
        	$scope.$watch('newOperation.contractor.certificate', function (newValue, oldValue) {
                if (newValue && newValue.length == 10 && newValue != oldValue) {
        			$scope.searchCompanyCertificate(newValue);
                }
            });
        };

        $scope.loadCompanyCertificate();
    }]);

